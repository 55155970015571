import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type MapFiltersOil = {
	facilities: {
		showSurface: boolean
		showUnderwater: boolean
	}
	fields: {
		showGasCondensate: boolean
		showOilGas: boolean
		showGas: boolean
		showOil: boolean
	}
}

export const defaultState: MapFiltersOil = {
	facilities: {
		showSurface: true,
		showUnderwater: true,
	},
	fields: {
		showGasCondensate: true,
		showOilGas: true,
		showGas: true,
		showOil: true,
	},
}

const storedState = localStorage.getItem("mapFiltersOil")
export const initialState: MapFiltersOil =
	storedState !== null ? JSON.parse(storedState) : defaultState

const mapFiltersOilSlice = createSlice({
	name: "mapFiltersOil",
	initialState,
	reducers: {
		updateMapFiltersOil(state, action: PayloadAction<MapFiltersOil>) {
			localStorage.setItem("mapFiltersOil", JSON.stringify(action.payload))
			return { ...state, ...action.payload }
		},
		resetMapFiltersOil(state) {
			localStorage.setItem("mapFiltersOil", JSON.stringify(defaultState))
			return { ...state, ...defaultState }
		},
	},
})

export const { updateMapFiltersOil, resetMapFiltersOil } = mapFiltersOilSlice.actions

export const getMapFiltersOil = (state: RootState) => state.mapFiltersOil
export default mapFiltersOilSlice.reducer
