import { ContentWrapper } from "./ContentWrapper"
import { IReportGeneratorProps, ReportGenerator } from "./ReportGenerator"
import { ReportGeneratorTrigger, ReportGeneratorTriggerOnLoading } from "./ReportGeneratorTrigger"
import { ReportPageBody } from "./ReportPageBody"
import ReportPageFooterDipai from "./ReportPageFooterDipai"
import ReportPageHeaderDipai from "./ReportPageHeaderDipai"

function ReportGeneratorDipai(props: IReportGeneratorProps) {
	return (
		<ReportGenerator
			{...props}
			ReportPageHeader={ReportPageHeaderDipai}
			ReportPageBody={ReportPageBody}
			ReportPageFooter={ReportPageFooterDipai}
		/>
	)
}

const ReportGeneratorDipaiNamespace = Object.assign(ReportGeneratorDipai, {
	ContentWrapper: ContentWrapper,
	Trigger: ReportGeneratorTrigger,
	TriggerOnLoading: ReportGeneratorTriggerOnLoading,
})
export { ReportGeneratorDipaiNamespace as ReportGeneratorDipai } //, buttonVariants }
