export const LABELS_MONTHS: [string, string, number][] = [
	["JAN", "JANUARY", 0],
	["FEB", "FEBRUARY", 1],
	["MAR", "MARCH", 2],
	["APR", "APRIL", 3],
	["MAY", "MAY", 4],
	["JUN", "JUNE", 5],
	["JUL", "JULY", 6],
	["AUG", "AUGUST", 7],
	["SEP", "SEPTEMBER", 8],
	["OCT", "OCTOBER", 9],
	["NOV", "NOVEMBER", 10],
	["DEC", "DECEMBER", 11],
]

export function getLabelsMonths() {
	const monthRef = new Date().getMonth()
	const currentLabels = LABELS_MONTHS.slice(0, monthRef + 1)
	return currentLabels.concat(LABELS_MONTHS.slice(monthRef + 1, 12 - monthRef + 1).reverse())
}
