import { PageObject, Pages } from "@utilityTypes/types"
import { AppStructure, CustomerData, DipaiModule } from "api"
import {
	DIPAI,
	FALL_BACK,
	FLEET_OVERVIEW,
	MAINTENANCE,
	OPERATION,
	OPERATION2,
} from "./modulesStructure"

export const getPagesPerCompany = (
	appStructure: AppStructure
): { [companyName: string]: Pages } => {
	const pagesPerCompany: { [companyName: string]: Pages } = {}
	const AppPages = new Map<DipaiModule, Pages>()

	AppPages.set(DipaiModule.MAINTENANCE, MAINTENANCE)
	// AppPages.set(DipaiModule.MAINSTAY, MAINTENANCE)
	AppPages.set(DipaiModule.MAINSTAY, OPERATION)
	AppPages.set(DipaiModule.FLEET_OVERVIEW, FLEET_OVERVIEW)
	AppPages.set(DipaiModule.DIPAI, DIPAI)
	AppPages.set(DipaiModule.OPERATION, OPERATION)
	AppPages.set(DipaiModule.OPERATION2, OPERATION2)

	const filterPages = (listOfCustomerData: { [customerName: string]: CustomerData }) => {
		for (const companyName in listOfCustomerData) {
			const customerData = listOfCustomerData[companyName]
			if (customerData === undefined) {
				continue
			}
			const pages = AppPages.get(customerData.module)

			let filteredPages: Pages = {}
			const linksToRemove: string[] = []

			filteredPages = filterFunction(pages, linksToRemove, customerData.features)
			pagesPerCompany[companyName] = filteredPages

			const listOfClients = customerData.clients
			if (Object.keys(listOfClients).length > 0) {
				filterPages(listOfClients)
			}
		}
	}

	const filterFunction = (
		pages: Pages | undefined,
		listOfLinksToRemove: string[],
		customerFeatures: string[]
	): Pages => {
		const listOfFilteredPages: Pages = {}
		if (pages !== undefined) {
			for (const page in pages) {
				const pageObj = pages[page]
				if (pageObj === undefined) {
					continue
				}
				if (pageObj.feature !== undefined) {
					if (customerFeatures.includes(pageObj.feature!) === true) {
						if (pageObj.sub_pages !== undefined) {
							let listOfFilteredSubpages: Pages = {}
							listOfFilteredSubpages = filterFunction(
								pageObj.sub_pages,
								listOfLinksToRemove,
								customerFeatures
							)
							pageObj.sub_pages = listOfFilteredSubpages
						}
						listOfFilteredPages[page] = pageObj
					} else {
						listOfLinksToRemove.push(page)
						for (const filteredPage in listOfFilteredPages) {
							const filteredPageData = listOfFilteredPages[filteredPage]
							if (filteredPageData === undefined) {
								continue
							}
							const pageLinksTemp = filteredPageData.page_links.filter(
								(link) => link !== page
							)
							filteredPageData.page_links = pageLinksTemp
						}
					}
				} else {
					const pageObjTemp: PageObject = JSON.parse(JSON.stringify(pageObj))
					const pageLinksTemp = pageObj.page_links.filter(
						(link) => !listOfLinksToRemove.includes(link)
					)
					pageObjTemp.page_links = pageLinksTemp
					if (pageObj.sub_pages !== undefined) {
						let listOfFilteredSubpages: Pages = {}
						listOfFilteredSubpages = filterFunction(
							pageObj.sub_pages,
							listOfLinksToRemove,
							customerFeatures
						)
						pageObjTemp.sub_pages = listOfFilteredSubpages
					}
					listOfFilteredPages[page] = pageObjTemp
				}
			}
		} else {
			const fallBackPage = FALL_BACK["NOT_FOUND"]
			if (fallBackPage !== undefined) {
				listOfFilteredPages["NOT_FOUND"] = fallBackPage
			}
		}
		return listOfFilteredPages
	}

	filterPages(appStructure)

	return pagesPerCompany
}
