import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type State = {
	isOpen: boolean
	selectedTab: string
	isTableExpanded: boolean
}

export const initialState: State = {
	isOpen: true,
	selectedTab: "1",
	isTableExpanded: false,
}

const mapSidePanelControlSlice = createSlice({
	name: "mapSidePanelControl",
	initialState,
	reducers: {
		updateSidePanelState(state, action: PayloadAction<State>) {
			return { ...state, ...action.payload }
		},
	},
})

export const getPanelState = (state: RootState) => state.mapSidePanelControl
export const { updateSidePanelState } = mapSidePanelControlSlice.actions
export default mapSidePanelControlSlice.reducer
