import { Chart as ChartJS, ChartType } from "chart.js"

declare module "chart.js" {
	interface PluginOptionsByType<TType extends ChartType> {
		customLineTooltip?: { enabled?: boolean }
	}
}

export const customLineTooltipPlugin = {
	id: "customLineTooltip",
	beforeDatasetsDraw: function (
		chart: ChartJS,
		args: { cancelable: true },
		options: { enabled: true }
	) {
		if (options.enabled !== true) {
			return
		}
		if (chart?.tooltip?.title === undefined) {
			return
		}

		const activeElements = chart.tooltip.getActiveElements()
		if (activeElements.length === 0) {
			return
		}
		const activePoint = activeElements[0]
		const y_axis = chart.scales.y
		if (activePoint === undefined || y_axis === undefined) {
			return
		}
		const ctx = chart.ctx
		const x = activePoint.element.tooltipPosition(true).x
		const topY = y_axis.top
		const bottomY = y_axis.bottom

		// draw line
		ctx.save()
		ctx.beginPath()
		ctx.moveTo(x, topY)
		ctx.lineTo(x, bottomY)
		ctx.setLineDash([6, 6])
		ctx.lineWidth = 1
		ctx.strokeStyle = "rgba(255,0,0,0.75)"
		ctx.stroke()
		ctx.restore()
	},
}
