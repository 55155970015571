import { RequestStatus } from "@redux/app/RequestStatus"
import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { MrvSubEventConverted } from "@sop/EmissionPage/Mrv/MrvUtils/convertMrvSubEvents"
import { MrvEventType } from "api"

export type MrvSubEventForm = Omit<MrvSubEventConverted, "timestamp" | "id"> & {
	id: number | undefined
	date: string
	time: string
}

export const INIT_MRV_FORM_STATE: MrvSubEventForm = {
	id: undefined,
	distance_nm: 0,
	date: "",
	time: "",
	consumption: {},
	location: null,
	latitude_dd: 0,
	longitude_dd: 0,
	remainingOnBoard: {},
	type: MrvEventType.Arrival,
	volume_m3: null,
	weight_tonnes: null,
	autoModifyAllowed: false,
}

type SelectedMrvSubEvent = {
	mrvSubEvent: MrvSubEventForm
	timeConstraint:
		| { timeFrom: string; dateFrom: string; timeTo: string; dateTo: string }
		| undefined
	showForm: { mrvEventId: number | undefined; open: boolean }
	status: RequestStatus
	error: string
}

const initialState: SelectedMrvSubEvent = {
	mrvSubEvent: INIT_MRV_FORM_STATE,
	timeConstraint: { timeFrom: "", dateFrom: "", timeTo: "", dateTo: "" },
	showForm: { mrvEventId: undefined, open: false },
	status: RequestStatus.idle,
	error: "",
}

const selectedMrvSubEvent = createSlice({
	name: "selectedMrvSubEvent",
	initialState,
	reducers: {
		setSelectedMrvSubEvent(
			state,
			action: PayloadAction<{
				mrvSubEvent: MrvSubEventForm
				timeConstraint:
					| {
							timeFrom: string
							dateFrom: string
							timeTo: string
							dateTo: string
					  }
					| undefined
			}>
		) {
			return {
				...state,
				mrvSubEvent: { ...state.mrvSubEvent, ...action.payload.mrvSubEvent },
				timeConstraint: action.payload.timeConstraint,
			}
		},
		setShowMrvSubEventForm(
			state,
			action: PayloadAction<{ mrvEventId: number | undefined; open: boolean }>
		) {
			return { ...state, showForm: action.payload }
		},
		clearSelectedMrvSubEvent(state) {
			return { ...state, mrvSubEvent: INIT_MRV_FORM_STATE }
		},
	},
})

export const getSelectedMrvSubEvent = (state: RootState) => state.selectedMrvSubEvent.mrvSubEvent
export const getSelectedMrvSubEventTimeConstraints = (state: RootState) =>
	state.selectedMrvSubEvent.timeConstraint

export const getSelectedMrvSubEventFormState = (state: RootState) =>
	state.selectedMrvSubEvent.showForm

export const { setSelectedMrvSubEvent, clearSelectedMrvSubEvent, setShowMrvSubEventForm } =
	selectedMrvSubEvent.actions

export default selectedMrvSubEvent.reducer
