import { BunkerReportEntry, BunkerReportFuelType } from "api"

export type BunkerReportEntryFE = Omit<BunkerReportEntry, "dateUTC"> & {
	dateUTC: { date: string; time: string }
}
export const initData: BunkerReportEntryFE = {
	bunkerDeliveryNoteNo: "",
	dateUTC: { date: "", time: "" },
	fuelType: BunkerReportFuelType.MGO,
	massMT: 0,
	densityAt15degree: null,
	bunkerPort: null,
	grade: null,
	higherHeatingValue: null,
	lowerHeatingValue: null,
	sulphurContent: null,
	tankToWakeCo2Factor: null,
	viscosity: null,
	waterContent: null,
}
