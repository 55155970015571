import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Facility } from "api"

const initialState: Facility[] = []

const facilitiesSlice = createSlice({
	name: "facilities",
	initialState,
	reducers: {
		setFacilities(state, action: PayloadAction<Facility[]>) {
			return action.payload
		},
	},
})

export const getFacilities = (state: RootState) => state.facilities

export const { setFacilities } = facilitiesSlice.actions

export default facilitiesSlice.reducer
