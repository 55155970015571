import { Callback } from "@utilityTypes/Callback"
import { logError } from "./logError"

export class EventSource<T = void> {
	private readonly listeners = new Set<Callback<T>>()

	addListener(callback: Callback<T>): Callback<T> {
		this.listeners.add(callback)
		return callback
	}

	emit(data: T) {
		this.listeners.forEach((l) => l(data))
	}

	removeListener(callback: Callback<T>) {
		this.listeners.delete(callback)
	}

	tryEmit(data: T, onError = logError) {
		this.listeners.forEach((l) => {
			try {
				l(data)
			} catch (error) {
				try {
					onError(error)
				} catch (error2) {
					console.error(error2)
				}
			}
		})
	}
}
