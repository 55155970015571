import LoginPage from "@components/login/LoginPage"
import { handleMicrosoftLoginResponse } from "@components/login/microsoft/LoginMicrosoft"
import { loadPage } from "@helpers/lazyLoadComponent"
import { RequestStatus } from "@redux/app/RequestStatus"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import { updateIsConnected } from "@redux/features/appState/appStateSlice"
import {
	fetchUserData,
	getUserData,
	getUserDataStatus,
} from "@redux/features/userData/userDataSlice"
import { LoadingCircle } from "@ui-lib/src"
import "@utils/chartJS/chartSetup"
import { common, scanClient } from "@utils/common"
import { requestApi2 } from "@utils/http"
import { getUserWebSocket } from "@utils/websocket/getUserWebSocket"
import { socketIoSocket } from "@utils/websocket/socketIoSocket"
import { markUserIsAuthenticated } from "@utils/websocket/userTrackingSocket"
import { DipaiModule, endpoints, messageTypes } from "api"
import React, { useEffect, useState } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import "regenerator-runtime/runtime"
import { getAppState } from "./app/store"
import {
	fetchAppStructure,
	getAppStructure,
	getAppStructureStatus,
} from "./features/appStructure/appStructureSlice"
import { setCurrentCompany } from "./features/currentCompany/currentCompanySlice"
import { fetchStateAliases, getStateAliases } from "./features/stateAliases/stateAliasesSlice"
import { pca } from "./index"

export default function App() {
	const [pages, setPages] = useState<React.ReactElement[] | null>(null)
	const [pagesLoaded, setPagesLoaded] = useState<boolean>(false)
	const [companyName, setCompanyName] = useState<string>()
	const [isSignedIn, setIsSignedIn] = useState(false)

	const dispatch = useAppDispatch()

	const userData = useAppSelector(getUserData)
	const userDataStatus = useAppSelector(getUserDataStatus)

	const appStruct = useAppSelector(getAppStructure)
	const appStructureStatus = useAppSelector(getAppStructureStatus)

	const stateAliases = useAppSelector(getStateAliases)

	const appState = useAppSelector(getAppState)

	const wsClient = getUserWebSocket()

	useEffect(() => {
		pca.handleRedirectPromise()
			.then((response) => {
				console.log("🚀 ~ response:", response)

				if (response !== null) {
					handleMicrosoftLoginResponse(response, dispatch)
				}
			})
			.catch((error) => {
				console.error("Redirect error: ", error)
			})
	}, [])

	const signIn = async () => {
		const key = await requestApi2(endpoints.getSignInKey)
		if (key !== null) {
			wsClient.send(messageTypes.signIn, key)
			setIsSignedIn(true)
		}
	}

	useEffect(() => {
		const onConnected = () => dispatch(updateIsConnected(true))
		const onDisconnected = () => dispatch(updateIsConnected(false))
		socketIoSocket.onConnected.addListener(onConnected)
		socketIoSocket.onDisconnected.addListener(onDisconnected)
		return () => {
			socketIoSocket.onConnected.removeListener(onConnected)
			socketIoSocket.onDisconnected.removeListener(onDisconnected)
		}
	}, [])

	useEffect(() => {
		if (isSignedIn && (!appState.isConnected || !userData.isAuthenticated)) {
			setIsSignedIn(false)
		}
		if (appState.isConnected && userData.isAuthenticated && !isSignedIn) {
			signIn()
		}
	}, [appState.isConnected, userData.isAuthenticated])

	useEffect(() => {
		if (userData.isAuthenticated) {
			markUserIsAuthenticated()
		}
	}, [userData.isAuthenticated])

	useEffect(() => {
		if (userDataStatus === RequestStatus.idle) {
			dispatch(fetchUserData())
		}
	}, [userDataStatus])

	useEffect(() => {
		if (userData.isAuthenticated === true) {
			if (appStruct === null) {
				dispatch(fetchAppStructure())
				setPagesLoaded(false)
				setCompanyName(undefined)
				dispatch(setCurrentCompany(undefined))
				setPages(null)
			}
			if (stateAliases === null) {
				dispatch(fetchStateAliases())
			}
		}
	}, [userData])

	useEffect(() => {
		if (appStructureStatus === RequestStatus.succeeded) {
			console.log("😎 ~ appStruct", appStruct)
			common.sensorsById.clear()
			if (appStruct === null) {
				return
			}
			setCompanyName(Object.keys(appStruct)[0])
			for (const client of Object.keys(appStruct)) {
				const clientRef = appStruct[client]
				if (clientRef === undefined) {
					continue
				}
				scanClient(clientRef, client, client)
			}
		}
	}, [appStruct, dispatch])

	useEffect(() => {
		if (appStruct === null) {
			setPages(null)
			setPagesLoaded(false)
			return
		}
		if (
			companyName === undefined ||
			(appStruct !== null && Object.keys(appStruct)[0] !== companyName)
		) {
			setCompanyName(Object.keys(appStruct)[0])
			setPages(null)
			setPagesLoaded(false)
			return
		}
		if (pagesLoaded === true) {
			return
		}
		const companyData = appStruct[companyName]
		if (companyData === undefined) {
			return
		}
		const routes: React.ReactElement[] = []
		if (
			companyData.module === DipaiModule.OPERATION2 ||
			companyData.module === DipaiModule.DIPAI
		) {
			routes.push(
				<Route
					key={`route_root_redirect`}
					path={"/"}
					element={
						companyName.toLowerCase() === "dipai" ? (
							<Navigate to="/clients" replace />
						) : (
							<Navigate to="/fleet overview" replace />
						)
					}
				/>
			)
		}
		for (const pageName of Object.keys(companyData.pages)) {
			const pageRef = companyData.pages[pageName]
			if (pageRef === undefined) {
				continue
			}
			routes.push(
				<Route
					key={`route_${pageName}`}
					path={pageRef.page_url}
					element={loadPage({
						pageRef: pageRef.page_ref,
						props: appStruct,
						path: pageRef.page_url,
						pageId: pageName,
					})}
				/>
			)
		}
		setPages(routes)
		setPagesLoaded(true)
	}, [appStruct, companyName])

	if (userData.isAuthenticated !== true) {
		return <LoginPage />
	}

	return appStruct !== null &&
		pages !== null &&
		companyName !== undefined &&
		Object.keys(appStruct)[0] === companyName ? (
		<Routes>{[...pages]}</Routes>
	) : (
		<LoadingCircle containerHeightWithUnit="100vh" />
	)
}
