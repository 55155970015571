import { RequestStatus } from "@redux/app/RequestStatus"
import { RootState } from "@redux/app/store"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { requestApi2 } from "@utils/http"
import { endpoints, StateAliasesOfUnit } from "api"

type State = {
	stateAliases: StateAliasesOfUnit[] | null
	status: RequestStatus
	error: string
}

const initialState: State = {
	stateAliases: null,
	status: RequestStatus.idle,
	error: "",
}

export const fetchStateAliases = createAsyncThunk<StateAliasesOfUnit[]>(
	"stateAliases/fetchStateAliases",
	async (): Promise<StateAliasesOfUnit[]> => {
		const data = await requestApi2(endpoints.getStateAliases)
		if (data !== null) {
			return data
		}
		return []
	}
)

const stateAliasesSlice = createSlice({
	name: "stateAliases",
	initialState,
	reducers: {
		setStateAliases(state, action: PayloadAction<StateAliasesOfUnit[]>) {
			return { ...state, stateAliases: { ...action.payload } }
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchStateAliases.pending, (state, action) => {
				state.status = RequestStatus.loading
			})
			.addCase(fetchStateAliases.fulfilled, (state, action) => {
				state.status = RequestStatus.succeeded
				state.stateAliases = action.payload
			})
			.addCase(fetchStateAliases.rejected, (state, action) => {
				state.status = RequestStatus.failed
				state.error = "" //action.error.message
			})
	},
})

export const getStateAliases = (state: RootState) => state.stateAliases.stateAliases
export const getStateAliasesStatus = (state: RootState) => state.stateAliases.status

export const { setStateAliases } = stateAliasesSlice.actions

export default stateAliasesSlice.reducer
