import { FC, ReactElement, Suspense } from "react"
import { MdHome } from "react-icons/md"
import { Link } from "react-router-dom"
import BrokenLinkIcon from "../../../assets/icons/BrokenLinkIcon.svg"
import { useSearchParamsState } from "../../../lib/hooks/useSearchParamsState"
import { Button } from "../../Button"
import { LoadingCircle } from "../../Loading"
import { usePageLayoutContext } from "../PageLayout"
import { HeaderLayout } from "./HeaderLayout"

export interface IBodyLayoutProps {
	placeHolderComponent?: ReactElement
	customerName?: string
}

export const BodyLayout: FC<IBodyLayoutProps> = (props: IBodyLayoutProps) => {
	const { placeHolderComponent } = props
	const [paramSelectedVessel, setParamSelectedVessel] = useSearchParamsState("vessel", "")
	const { selectedModule, selectedModuleMetadata, translationFunc } = usePageLayoutContext()
	selectedModuleMetadata?.feature
	return (
		<div
			className={`h-[calc(100%)] w-[calc(100%)] overflow-auto bg-dipai-secondary-200 bg-opacity-80`}
		>
			{placeHolderComponent !== undefined &&
			paramSelectedVessel === "" &&
			selectedModuleMetadata !== undefined &&
			selectedModuleMetadata.config?.hideVesselSelection !== true ? (
				<>
					<HeaderLayout
						label={selectedModule}
						translationFunc={translationFunc}
						customerName={props.customerName}
					/>
					{placeHolderComponent}
				</>
			) : (
				<Suspense
					fallback={
						<div className={`relative h-full w-full`}>
							<HeaderLayout
								label={selectedModule}
								translationFunc={translationFunc}
								customerName={props.customerName}
							/>
							<div className={`absolute left-[calc(50%-30px)] top-0`}>
								<LoadingCircle containerHeightWithUnit="100vh" />
							</div>
						</div>
					}
				>
					{selectedModuleMetadata?.content ?? (
						<div className={`h-screen bg-dipai-secondary-901 text-white`}>
							<div className="flex h-[52px] flex-row items-center justify-end">
								<p className="pr-4 text-small">
									{translationFunc !== undefined
										? translationFunc("PROBLEMS? PLEASE CONTACT ")
										: "Problems? Please contact "}
									<span className="font-semibold underline">Dipai Support</span>
								</p>
							</div>

							<div className="flex h-[calc(100%-104px)] w-full flex-row items-center justify-center">
								<div className={`flex flex-col items-center`}>
									<img className="mb-8 w-[100px]" src={BrokenLinkIcon} alt="" />
									<p className="mb-5 h-[49px] text-center text-title3">
										{translationFunc !== undefined
											? translationFunc("THIS PAGE ISN'T AVAILABLE")
											: "This page isn't available"}
									</p>
									<p className="m-auto mb-8 text-center text-subheadline">
										{translationFunc !== undefined
											? translationFunc(
													"THE LINK MAY BE BROKEN, OR YOU ARE TRYING TO ACCESS A PAGE THAT DOESN'T EXIST"
											  )
											: "The link may be broken, or you are trying to access a page that doesn't exist."}
									</p>
									<Link to={"../fleet overview"}>
										<Button>
											<Button.Icon>{MdHome}</Button.Icon>
											<Button.Label>
												{translationFunc !== undefined
													? translationFunc("GO TO HOME")
													: "Go to home"}
											</Button.Label>
										</Button>
									</Link>
								</div>
							</div>
						</div>
					)}
				</Suspense>
			)}
		</div>
	)
}
