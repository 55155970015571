import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type State = {
	isConnected: boolean
}

export const defaultState: State = {
	isConnected: false,
}

const appStateSlice = createSlice({
	initialState: defaultState,
	name: "appState",
	reducers: {
		updateIsConnected(state, action: PayloadAction<boolean>) {
			return { ...state, isConnected: action.payload }
		},
	},
})

export const { updateIsConnected } = appStateSlice.actions
export default appStateSlice.reducer
