import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type OfflineVesselsList = {
	name: string
	id: number
	lastUpdateTimestamp: number | null
}[]

const initialState: OfflineVesselsList = []

const offlineVesselsSlice = createSlice({
	name: "offlineVessels",
	initialState,
	reducers: {
		setOfflineVessels(state, action: PayloadAction<OfflineVesselsList>) {
			return action.payload
		},
	},
})

export const getOfflineVessels = (state: RootState) => state.offlineVessels

export const { setOfflineVessels } = offlineVesselsSlice.actions

export default offlineVesselsSlice.reducer
