import { AppStructureWithPages, Pages } from "@utilityTypes/types"
import { AppStructure } from "api"

export const appendPagesToAppStructure = (
	appStructure: AppStructure,
	pagesPerCompany: { [companyName: string]: Pages }
): AppStructureWithPages => {
	const dataWithPages: AppStructureWithPages = {}
	for (const companyName in appStructure) {
		const companyData = appStructure[companyName]
		if (companyData === undefined) {
			continue
		}
		const clientsData = appendPagesToAppStructure(companyData.clients, pagesPerCompany)
		dataWithPages[companyName] = {
			module: companyData.module,
			clients: clientsData,
			plants: companyData.plants,
			pages: pagesPerCompany[companyName] ?? {},
			features: companyData.features,
			companyId: companyData.companyId,
		}
	}
	return dataWithPages
}
