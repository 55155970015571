import { LABELS_MONTHS } from "./getLabelsMonths"

const padZero = (num: number): string => (num < 10 ? "0" + num : num.toString())

type DatePattern =
	| "DD/MM/YYYY - HH:MM:SS"
	| "DD MMM - HH:MM:SS"
	| "DD/MM/YYYY - HH:MM"
	| "DD/MM/YYYY"

export function formatDate(
	date: Date,
	pattern: DatePattern,
	withTimeZone: boolean = true,
	translateFun?: (text: string) => string,
	useAmPmMarker?: boolean
): string {
	const day = padZero(date.getDate())
	const month = padZero(date.getMonth() + 1)
	const year = date.getFullYear()
	const timezoneOffset = date.getTimezoneOffset()
	const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60)
	const offsetMinutes = Math.abs(timezoneOffset) % 60
	const timezone = ` GMT${timezoneOffset <= 0 ? "+" : "-"}${padZero(offsetHours)}:${padZero(
		offsetMinutes
	)}`
	const hours = padZero(
		useAmPmMarker === true
			? date.getHours() % 12 === 0
				? 12
				: date.getHours() % 12
			: date.getHours()
	)
	const minutes = padZero(date.getMinutes())
	const seconds = padZero(date.getSeconds())

	switch (pattern) {
		case "DD/MM/YYYY - HH:MM:SS":
			return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}${
				useAmPmMarker === true ? (date.getHours() >= 12 ? " PM" : " AM") : ""
			}${withTimeZone === true ? timezone : ""}`
		case "DD MMM - HH:MM:SS":
			const monthName = LABELS_MONTHS?.[date.getMonth()]?.[0] ?? ""
			const monthTranslated = translateFun !== undefined ? translateFun(monthName) : monthName
			return `${day} ${monthTranslated} - ${hours}:${minutes}:${seconds}${
				useAmPmMarker === true ? (date.getHours() >= 12 ? " PM" : " AM") : ""
			}${withTimeZone === true ? timezone : ""}`
		case "DD/MM/YYYY - HH:MM":
			return `${day}/${month}/${year} - ${hours}:${minutes}${
				useAmPmMarker === true ? (date.getHours() >= 12 ? " PM" : " AM") : ""
			}${withTimeZone === true ? timezone : ""}`

		case "DD/MM/YYYY":
			return `${day}/${month}/${year}${
				useAmPmMarker === true ? (date.getHours() >= 12 ? " PM" : " AM") : ""
			}${withTimeZone === true ? timezone : ""}`
		default:
			return date.toISOString()
	}
}

export function formatDateISOWithOffset(date: Date): string {
	const isoString = date.toISOString().slice(0, -1) // Remove the 'Z'

	const offset = -date.getTimezoneOffset()
	const sign = offset >= 0 ? "+" : "-"
	const hours = Math.floor(Math.abs(offset) / 60)
		.toString()
		.padStart(2, "0")
	const minutes = (Math.abs(offset) % 60).toString().padStart(2, "0")

	return `${isoString}${sign}${hours}:${minutes}`
}
