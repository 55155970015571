import { ReactElement } from "react"

export interface ILoadingCircleProps {
	size?: "sm" | "medium" | "lg"
	containerHeightWithUnit?: string
	containerWidthWithUnit?: string
}

const iconDimensions = { sm: 40, medium: 60, lg: 100 }

export function LoadingCircle({
	size = "medium",
	containerHeightWithUnit,
	containerWidthWithUnit,
}: ILoadingCircleProps) {
	const iSize = iconDimensions[size]

	const container = containerHeightWithUnit?.match(/^(\d+)(\D.*)/) // [fullString, number, unit,...]
	const cSize =
		container !== null && container !== undefined && container[1] !== undefined
			? parseFloat(container[1])
			: 0
	const cUnit = container !== null && container !== undefined ? container![2] : undefined

	return (
		<div
			className={`${
				container === null || container === undefined
					? "flex h-full flex-col justify-center"
					: ``
			}`}
			style={{
				height: containerHeightWithUnit !== undefined ? `${cSize}${cUnit}` : "",
				paddingTop:
					containerHeightWithUnit !== undefined
						? `calc(${cSize * 0.5}${cUnit} - ${iSize * 0.5}px)`
						: "",
			}}
		>
			<div
				className="relative flex content-around justify-center"
				style={{
					width: containerWidthWithUnit,
				}}
			>
				<svg
					className="absolute"
					style={{
						left: `calc(50% - ${iSize / 2}px + ${iSize * 0.21}px)`,
					}}
					height={iSize}
					width={iSize * 0.45}
					viewBox={`0 0 18.5397 23.36508`}
				>
					<g transform="translate(-0.22128881,-0)">
						<path
							className={"fill-white"}
							d="M 18.760989,0.27422034 V 22.9946 h -3.7345 c -0.6187,0 -1.1048,-0.4891 -1.1048,-1.1116 v -0.2001 c -0.7072,0.5781 -1.4806,1.0671 -2.2982,1.4228 -0.8176,0.3557 -1.6793602,0.5336 -2.5853502,0.5336 -1.21536,0 -2.38652,-0.2223 -3.44719,-0.6892 -1.08277,-0.4668 -2.01086,-1.1115 -2.80637,-1.9119 -0.7955,-0.8003 -1.41423,-1.7562 -1.87827099,-2.8456 -0.464044,-1.0893 -0.685019,-2.2231 -0.685019,-3.4458 0,-1.2227 0.220975,-2.401 0.685019,-3.4903 C 1.3703488,10.1671 2.0111788,9.2112003 2.8066788,8.4108703 c 0.79551,-0.80033 1.74569,-1.4228 2.82846,-1.88966 1.10487,-0.48909 2.27603,-0.7114 3.51348,-0.68917 0.8618002,0 1.7014702,0.20008 2.4969702,0.53355 0.8176,0.3557 1.591,0.82256 2.2761,1.37834 v -5.84683 c 0,-0.88925 0.7071,-1.60064996 1.591,-1.60064996 h 3.2483 z M 9.5684688,19.2375 c 0.6187302,0 1.2153202,-0.1112 1.7678202,-0.3557 0.5524,-0.2445 1.0164,-0.5558 1.4142,-0.9559 0.3978,-0.4002 0.7071,-0.8671 0.9502,-1.4228 0.2431,-0.5558 0.3535,-1.1338 0.3535,-1.7563 0,-0.6225 -0.1104,-1.2227 -0.3535,-1.7563 -0.2431,-0.5558 -0.5524,-1.0226 -0.9502,-1.4228 -0.3978,-0.4002 -0.8839,-0.7114 -1.4142,-0.9559 -0.5525,-0.2446 -1.127,-0.3557 -1.7678202,-0.3557 -0.61872,0 -1.21535,0.1111 -1.76779,0.3557 -0.55243,0.2445 -1.01647,0.5557 -1.41423,0.9559 -0.39775,0.4002 -0.70711,0.867 -0.95018,1.4228 -0.24307,0.5558 -0.35356,1.1338 -0.35356,1.7563 0,0.6225 0.11049,1.2227 0.35356,1.7563 0.24307,0.5557 0.55243,1.0226 0.95018,1.4228 0.39776,0.4001 0.8839,0.7114 1.41423,0.9559 0.55244,0.2223 1.12697,0.3557 1.76779,0.3557 z"
							style={{ width: iSize * 0.45 }}
						/>
					</g>
				</svg>

				<svg height={iSize} width={iSize} viewBox="0 0 24 24">
					<circle className={`fill-dipai-secondary-901`} cx="12" cy="12" r="12" />
					<circle
						className={`absolute inset-0 origin-center rotate-[35deg] animate-[loadingCircle_linear_2s_infinite] fill-none stroke-dipai-primary-501`}
						style={{
							strokeWidth: "2.4",
							strokeLinecap: "round",
							strokeDasharray: "0.01 62.8",
						}}
						cx="12"
						cy="12"
						r="9.6"
					/>
				</svg>
			</div>
		</div>
	)
}

export interface ILoadingCircleSimplesProps {
	size: number
	textColorTailwind: string
}

export function LoadingCircleSimples({
	size,
	textColorTailwind,
}: ILoadingCircleSimplesProps): ReactElement {
	return (
		<svg
			className={`animate-spin ${textColorTailwind}`}
			style={{ width: size, height: size }}
			fill="none"
			viewBox="0 0 24 24"
		>
			<circle
				className="opacity-25"
				cx="12"
				cy="12"
				r="10"
				stroke="currentColor"
				strokeWidth="4"
			/>
			<path
				className="opacity-75"
				fill="currentColor"
				d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
			/>
		</svg>
	)
}
