import { RequestStatus } from "@redux/app/RequestStatus"
import { RootState } from "@redux/app/store"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { requestUrlGetJson } from "@utils/http"
import { DiseaseZone, urls } from "api"
import { DateTime } from "luxon"

export enum diseaseZoneType {
	IlaProtectionZone = "ISA protection zone",
	IlaSurveillanceZone = "ISA surveillance zone",
	PdProtectionZone = "PD protection zone",
	PdSurveillanceZone = "PD surveillance zone",
	PdZone = "PD zone",
	IlaFreeArea = "ISA free area",
}

export type SelectedDiseaseZones = {
	[zoneType in diseaseZoneType]: boolean
}

export type DiseaseZonesObj = { [zoneName: string]: DiseaseZone[] }
type State = {
	diseaseZones: DiseaseZonesObj
	selectedDiseaseZones: SelectedDiseaseZones
	status: RequestStatus
	error: string
}

const defaultState: State = {
	diseaseZones: {},
	selectedDiseaseZones: {
		"ISA surveillance zone": true,
		"ISA free area": true,
		"ISA protection zone": true,
		"PD protection zone": true,
		"PD surveillance zone": true,
		"PD zone": true,
	},
	status: RequestStatus.idle,
	error: "",
}

const storedState = localStorage.getItem("selectedDiseaseZone")
export const initialState =
	storedState !== null
		? {
				...defaultState,
				selectedDiseaseZones: { ...JSON.parse(storedState) } as SelectedDiseaseZones,
		  }
		: defaultState

export const fetchDiseaseZones = createAsyncThunk<DiseaseZonesObj>(
	"diseaseZones/fetchDiseaseZones",
	async (): Promise<DiseaseZonesObj> => {
		const dt = DateTime.now()
		const week = dt.weekNumber
		const year = dt.year

		const postData = {
			year,
			week,
		}
		const res: DiseaseZonesObj = JSON.parse(
			(await requestUrlGetJson(urls.GET_DISEASE_ZONES, postData)) as string
		)
		return res
	}
)

const diseaseZonesSlice = createSlice({
	name: "diseaseZones",
	initialState,
	reducers: {
		updateSelectedDiseaseZones(state, action: PayloadAction<SelectedDiseaseZones>) {
			localStorage.setItem("selectedDiseaseZone", JSON.stringify(action.payload))
			return { ...state, selectedDiseaseZones: { ...action.payload } }
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchDiseaseZones.pending, (state, action) => {
				state.status = RequestStatus.loading
			})
			.addCase(fetchDiseaseZones.fulfilled, (state, action) => {
				state.status = RequestStatus.succeeded
				state.diseaseZones = action.payload
			})
			.addCase(fetchDiseaseZones.rejected, (state, action) => {
				state.status = RequestStatus.failed
				state.error = "" //action.error.message
			})
	},
})

export const getDiseaseZones = (state: RootState) => state.diseaseZones.diseaseZones

export const getSelectedDiseaseZones = (state: RootState) => state.diseaseZones.selectedDiseaseZones

export const getDiseaseZonesStatus = (state: RootState) => state.diseaseZones.status

export const {
	updateSelectedDiseaseZones,
	// clearSelectedFishFarmOwner,
} = diseaseZonesSlice.actions

export default diseaseZonesSlice.reducer
