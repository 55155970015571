import { FC, ReactNode, Suspense, createContext, useCallback, useMemo, useState } from "react"

type FallbackType = NonNullable<ReactNode> | null

type FallbackContextType = {
	updateFallback: (fallback: FallbackType) => void
}

export const FallbackContext = createContext<FallbackContextType>({
	updateFallback: () => {},
})

type FallbackProviderProps = {
	children: ReactNode
}

export const FallbackProvider: FC<FallbackProviderProps> = ({ children }) => {
	const [fallback, setFallback] = useState<FallbackType>(null)

	const updateFallback = useCallback((fallback: FallbackType) => {
		setFallback(() => fallback)
	}, [])

	const renderChildren = useMemo(() => {
		return children
	}, [children])

	return (
		<FallbackContext.Provider value={{ updateFallback }}>
			<Suspense fallback={fallback}>{renderChildren}</Suspense>
		</FallbackContext.Provider>
	)
}
