import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import useLocalStorage from "@hooks/useLocalStorage"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import { PortalMode, setUserPortalMode } from "@redux/features/userData/userDataSlice"
import { AppStructureWithPages } from "@utilityTypes/types"
import { Dispatch, FC, ReactElement, SetStateAction, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { usePageLayoutContext } from "../PageLayout"

const UserDropdown = ({
	isExpanded,
	portalMode,
	setPortalMode,
	userData,
	appStructure,
	logoutComponent,
	translationFunc,
}: {
	isExpanded: boolean
	portalMode: string | null
	setPortalMode: Dispatch<SetStateAction<string | null>>
	userData: {
		name: string
		profilePicture?: string | undefined
	}
	appStructure: AppStructureWithPages | null
	logoutComponent: ReactElement
	translationFunc: ReturnType<typeof useTranslation>["t"] | undefined
}) => {
	const { name, profilePicture } = userData
	const nameSplit = name.split(" ")
	const firstName = nameSplit[0]
	const firstNameInitial = firstName?.[0]
	const lastName = nameSplit[nameSplit.length - 1]
	const lastNameInitial = lastName?.[0]

	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger
				className={`${
					isExpanded === false ? `w-[60px]` : `w-[180px]`
				} text-white text-opacity-60 ring-inset transition-all duration-300 hover:bg-white hover:bg-opacity-20 hover:text-opacity-100 focus:outline-none focus:ring-[4px] focus:ring-dipai-primary-501 focus:ring-opacity-40`}
			>
				<div
					tabIndex={-1}
					className={`group flex ${
						isExpanded === false ? `w-[60px]` : `w-[180px]`
					} flex-row  `}
				>
					<div tabIndex={-1} className={`flex h-[51px] w-[50px] items-center pl-[14px]`}>
						{profilePicture !== undefined && profilePicture !== "" ? (
							<img
								className={`mr-[26px] h-[26px] rounded-full border-2 border-dipai-primary-501`}
								src={profilePicture}
								alt=""
							/>
						) : (
							<div
								className={`float-right inline-block h-[26px] w-[26px] rounded-full border-2 border-dipai-primary-501 text-center align-middle`}
							>
								<p className={`pt-1 text-[10px] font-bold uppercase`}>
									{`${firstNameInitial ?? "unknown user"}${
										nameSplit.length > 1 && lastNameInitial !== undefined
											? lastNameInitial
											: ""
									}`}
								</p>
							</div>
						)}
					</div>
					<div
						tabIndex={-1}
						className={`h-[51px] w-[130px] origin-left pr-4 ${
							isExpanded === false ? `scale-x-0` : `scale-x-100`
						} transition-all duration-300`}
					>
						<div className={`flex h-full items-center overflow-hidden`}>
							<p
								className={`text-left text-button font-semibold leading-[16px] text-white transition-all duration-300 group-hover:text-opacity-100 ${
									isExpanded === false
										? `w-[110px] text-opacity-0`
										: `w-[110px] text-opacity-60`
								}`}
							>
								{`${firstName} ${nameSplit.length > 1 ? lastName : ""}`}
							</p>
						</div>
					</div>
				</div>
			</DropdownMenu.Trigger>
			<DropdownMenu.Content
				onCloseAutoFocus={(e) => e.preventDefault()}
				side={"right"}
				sideOffset={-10}
				align={"end"}
				alignOffset={10}
				className="z-[1000] mt-2 w-[200px] rounded-sm border-1 border-dipai-secondary-901 bg-dipai-secondary-800 p-[6px] text-white text-opacity-60 shadow-popup"
			>
				{location.pathname.includes("/clients") === true &&
				appStructure !== null &&
				Object.keys(appStructure)[0] === "Dipai" ? (
					<>
						<DropdownMenu.Label className={`px-4 py-2 text-tiny text-slate-200`}>
							{`Portal mode`}
						</DropdownMenu.Label>
						<DropdownMenu.RadioGroup
							value={portalMode !== null ? portalMode : undefined}
							onValueChange={setPortalMode}
						>
							<DropdownMenu.RadioItem
								onSelect={(event) => event.preventDefault()}
								className="relative flex items-center pl-4 rounded-sm cursor-default ring-inset hover:bg-white hover:bg-opacity-20 hover:text-white hover:text-opacity-100 hover:outline-none"
								value={PortalMode.PROD}
							>
								<DropdownMenu.ItemIndicator className="absolute left-0 inline-flex items-center justify-center w-4">
									<div className={`h-[6px] w-[6px] rounded-full bg-white`} />
								</DropdownMenu.ItemIndicator>
								<p
									className={`${
										portalMode === PortalMode.PROD
											? "text-white"
											: "text-slate-300"
									}`}
								>
									{"Production"}
								</p>
							</DropdownMenu.RadioItem>
							<DropdownMenu.RadioItem
								onSelect={(event) => event.preventDefault()}
								className="relative flex items-center pl-4 rounded-sm cursor-default ring-inset hover:bg-white hover:bg-opacity-20 hover:text-white hover:text-opacity-100 hover:outline-none"
								value={PortalMode.DEV}
							>
								<DropdownMenu.ItemIndicator className="absolute left-0 inline-flex items-center justify-center w-4">
									<div className={`h-[6px] w-[6px] rounded-full bg-white`} />
								</DropdownMenu.ItemIndicator>
								<p
									className={`${
										portalMode === PortalMode.DEV
											? "text-white"
											: "text-slate-300"
									}`}
								>
									{"Development"}
								</p>
							</DropdownMenu.RadioItem>
						</DropdownMenu.RadioGroup>

						<DropdownMenu.Separator className={`my-2 h-[1px] bg-slate-300`} />
					</>
				) : null}
				<DropdownMenu.Item className="cursor-pointer rounded-sm px-4 py-1 text-menu text-slate-200 ring-inset hover:bg-white hover:bg-opacity-20 hover:text-white hover:text-opacity-100 hover:outline-none focus:outline-none focus:ring-[4px] focus:ring-dipai-primary-501 focus:ring-opacity-0">
					{logoutComponent}
				</DropdownMenu.Item>
			</DropdownMenu.Content>
		</DropdownMenu.Root>
	)
}

export interface IUserDropdownProps {
	userData: { name: string; profilePicture?: string | undefined }
	logoutComponent: ReactElement
}

export const MainNavUserDropdown: FC<IUserDropdownProps> = (props: IUserDropdownProps) => {
	const appStructure = useAppSelector(getAppStructure)
	const dispatch = useAppDispatch()

	const defaultPortalMode = PortalMode.PROD
	const [portalMode, setPortalMode] = useLocalStorage("portalMode", defaultPortalMode)

	useEffect(() => {
		if (portalMode === null) {
			return
		}
		dispatch(setUserPortalMode(portalMode as PortalMode))
	}, [portalMode])

	const { userData, logoutComponent: Logout } = props
	const { isMainNavExpanded, isMainNavPopupExpanded, translationFunc } = usePageLayoutContext()

	return (
		<div
			className={`border-t-1 border-t-gray-900 border-opacity-20 shadow-[inset_0px_1.5px_1px_hsla(0,0%,100%,0.15)]`}
		>
			<div className={`hidden lg:flex`}>
				<UserDropdown
					isExpanded={isMainNavExpanded}
					appStructure={appStructure}
					logoutComponent={Logout}
					portalMode={portalMode}
					setPortalMode={setPortalMode}
					userData={userData}
					translationFunc={translationFunc}
				/>
			</div>
			<div className={`flex sm:flex lg:hidden`}>
				<UserDropdown
					isExpanded={isMainNavPopupExpanded}
					appStructure={appStructure}
					logoutComponent={Logout}
					portalMode={portalMode}
					setPortalMode={setPortalMode}
					userData={userData}
					translationFunc={translationFunc}
				/>
			</div>
		</div>
	)
}
