import { AuthenticationResult, BrowserAuthError } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { isMobileDevice } from "@helpers/isMobileDevice"
import { useAppDispatch } from "@redux/app/hooks"
import { AppDispatch } from "@redux/app/store"
import { setUserData } from "@redux/features/userData/userDataSlice"
import { requestApi2 } from "@utils/http"
import { LoginMethod, authEndpoints } from "api"

export const handleMicrosoftLoginResponse = async (
	loginRes: AuthenticationResult | null,
	dispatch: AppDispatch
) => {
	if (loginRes === null) {
		alert("Authentication filed. Please contact Dipai support for help.")
		return
	}
	const userAuthData = await requestApi2(authEndpoints.logIn, {
		method: LoginMethod.MICROSOFT,
		token: loginRes.accessToken,
	})
	if (userAuthData !== null) {
		if (userAuthData.isAuthenticated === true) {
			dispatch(
				setUserData({
					name: userAuthData.name,
					email: userAuthData.email,
					isAuthenticated: userAuthData.isAuthenticated,
					profilePicture: userAuthData.profilePicture,
				})
			)
		} else {
			alert("Authentication filed. Please contact Dipai support for help.")
		}
	} else {
		alert("Login failed. Please contact Dipai support for help.")
	}
}

export default function LoginMicrosoft() {
	const { instance } = useMsal()
	const dispatch = useAppDispatch()

	const loginMS = async () => {
		try {
			const loginRequest = {
				scopes: [`356b82d5-8dc7-4e54-b9df-fab8157f6c9f/.default`],
				prompt: "select_account",
			}
			let loginRes: AuthenticationResult | undefined | void

			if (isMobileDevice() === true) {
				await instance.loginRedirect(loginRequest)
			} else {
				loginRes = await instance.loginPopup(loginRequest)
			}
			if (loginRes === undefined) {
				throw new Error("Authentication failed")
			}
			handleMicrosoftLoginResponse(loginRes, dispatch)
		} catch (error) {
			if (error instanceof BrowserAuthError) {
				if (error.errorCode !== "user_cancelled") {
					alert(error)
				}
			} else {
				alert(error)
			}
		}
	}

	return (
		<button className="h-9 w-[201px] text-subheadline font-semibold" onClick={loginMS}>
			Sign in with Microsoft
		</button>
	)
}
