import { DetailedHTMLProps, HTMLAttributes } from "react"

export interface IReportGeneratorTrigger
	extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {}

export function ReportGeneratorTrigger(props: IReportGeneratorTrigger) {
	const { children, ...rest } = props

	return <span {...rest}>{children}</span>
}

export function ReportGeneratorTriggerOnLoading(props: IReportGeneratorTrigger) {
	const { children, ...rest } = props

	return <span {...rest}>{children}</span>
}
