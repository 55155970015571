import { cva, VariantProps } from "class-variance-authority"
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from "react"
import { cn } from "../../lib/utils"
import { PageCardBody } from "./PageCardBody"
import { PageCardSection } from "./PageCardSection"

const pageCardVariants = cva("h-auto min-h-[calc((100vh-52px-49px-60px)/2)] w-full rounded-sm", {
	variants: {
		company: {
			dipai: "bg-white",
		},
		printMode: {
			false: "shadow-card p-3",
			true: "",
		},
	},
	defaultVariants: {
		company: "dipai",
		printMode: false,
	},
})

export interface IPageCardProps
	extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
		VariantProps<typeof pageCardVariants> {}

const PageCard = forwardRef<HTMLDivElement, IPageCardProps>((props, ref) => {
	const { className, children, company, printMode, ...rest } = props
	return (
		<div
			ref={ref}
			className={cn(pageCardVariants({ company, printMode, className }))}
			{...rest}
		>
			{props.children}
		</div>
	)
})

const PageCardNamespace = Object.assign(PageCard, {
	Section: PageCardSection,
	Body: PageCardBody,
})

export { PageCardNamespace as PageCard, pageCardVariants }
