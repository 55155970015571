import { AssetObject, CustomerData, PlantObject, Sensor } from "api"

export interface FESensor extends Sensor {
	asset: AssetObject
	ownerName: string
	plantName: string
	assetName: string
}

export const common = {
	sensorDataFromTopics: new Map<string, Sensor>(),
	sensorDataFromIds: new Map<number, FESensor>(),
	plantDataFromOwnerAndName: new Map<string, PlantObject>(),
	plantDataFromOwnerAndId: new Map<string, PlantObject>(),
	plantDataFromName: new Map<string, PlantObject>(),
	sensorsById: new Map<number, FESensor[]>(),
	companyDataFromCompanyName: new Map<string, CustomerData>(),
	listenersAdded: false,
}

export function getSensorIdFromSensorTopic(topic: string): number | undefined {
	const sensorData = common.sensorDataFromTopics.get(topic)
	if (sensorData !== undefined) {
		return sensorData.id
	}
	return undefined
}

export enum ContextType {
	OWNER = "ownerName",
	PLANT = "plantName",
	ASSET = "assetName",
}

export function getSensorByIdAndContext(
	sensorId: number,
	contextType?: ContextType,
	contextValue?: string
): FESensor | undefined {
	const listOfSensors = common.sensorsById.get(sensorId)
	if (listOfSensors === undefined) {
		return undefined
	}
	if (contextType === undefined || contextValue === undefined) {
		return listOfSensors[0]
	}
	const sensor = listOfSensors.filter((sensor) => sensor[contextType] === contextValue)
	if (sensor.length === 0) {
		return undefined
	}
	return sensor[0]
}

export function scanClient(client: CustomerData, mainClientName: string, subClientName: string) {
	common.companyDataFromCompanyName.set(subClientName, client)
	for (const plantName of Object.keys(client.plants)) {
		const plant = client.plants[plantName]
		if (plant === undefined) {
			continue
		}
		common.plantDataFromOwnerAndName.set(
			`${subClientName.toLowerCase()}/${plant.display_name.toLowerCase()}`,
			plant
		)
		common.plantDataFromOwnerAndId.set(`${subClientName.toLowerCase()}/${plant.id}`, plant)
		common.plantDataFromName.set(`${plant.display_name}`, plant)

		for (const asset of plant.assets) {
			for (const sensor of asset.sensors) {
				const topic = `${mainClientName}/${subClientName}/${plant.display_name}/${asset.display_name}/${sensor.display_name}`
				common.sensorDataFromTopics.set(topic, sensor)
				const feSensor: FESensor = {
					...sensor,
					asset: asset,
					ownerName: subClientName,
					plantName: plant.display_name,
					assetName: asset.display_name,
				}
				common.sensorDataFromIds.set(sensor.id, feSensor)
				let sensorsOfId = common.sensorsById.get(sensor.id)
				if (sensorsOfId === undefined) {
					sensorsOfId = []
					common.sensorsById.set(sensor.id, sensorsOfId)
				}
				sensorsOfId.push(feSensor)
			}
		}
	}
	for (const subClientNameKey of Object.keys(client.clients)) {
		const clientsData = client.clients[subClientNameKey]
		if (clientsData === undefined) {
			return
		}
		scanClient(clientsData, subClientName, subClientNameKey)
	}
}
