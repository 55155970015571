export function HeaderBrandingSeaq() {
	return (
		<div className={`-mr-[10px] flex min-w-[320px] flex-row justify-end gap-3`}>
			<p className={`min-w-[100px] pt-[14px] text-body font-medium text-white`}>Powered by</p>
			<img
				src={`/assets/img/logos/SeaqWhite.svg`}
				alt={"SeaQ logo"}
				className="mt-[18px] h-[20px] w-[98px]"
			/>
			<img
				src={`/assets/img/logos/Vard.svg`}
				alt={"Vard logo"}
				className="mt-[18px] h-[20px] w-[94px]"
			/>
		</div>
	)
}
