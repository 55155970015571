import { EventSource } from "@utils/EventSource"
import { socketio } from "api"
import { io, Socket } from "socket.io-client"
import { z } from "zod"

class SocketIoSocket {
	readonly onConnected = new EventSource()
	readonly onDisconnected = new EventSource()

	readonly #listeners = new Map<socketio.SocketIoMessageType, ((data: unknown) => void)[]>()

	#socket: Socket | undefined

	constructor() {
		let socket: Socket
		if (
			window.location.hostname == "localhost" &&
			!window.location.search.includes("env=prod")
		) {
			socket = io(":8081")
		} else {
			socket = io()
		}
		socket.on("connect", () => this.#onConnect(socket))
		socket.on("disconnect", () => this.#onDisconnect())
	}

	get isConnected() {
		return this.#socket != undefined
	}

	addMessageListener<K extends socketio.SocketIoMessageType>(
		key: K,
		fn: (data: z.infer<(typeof socketio.socketIoMessageTypes)[K]>) => void
	) {
		let array = this.#listeners.get(key)
		if (array == undefined) {
			array = []
			this.#listeners.set(key, array)
		}
		array.push((data) => {
			const parser = socketio.socketIoMessageTypes[key]
			fn(parser.parse(data))
		})
	}

	sendMessage<K extends socketio.SocketIoMessageType>(
		key: K,
		data: socketio.SocketIoMessageData<K>
	) {
		this.#socket?.emit("message", { key, data } satisfies socketio.Message)
	}

	#onConnect(socket: Socket) {
		this.#socket = socket
		this.#socket?.on("message", (m: socketio.Message) => this.#onMessage(m))
		this.#socket?.on("disconnect", () => this.#onDisconnect())
		this.onConnected.tryEmit()
	}

	#onDisconnect() {
		this.#socket = undefined
		this.onDisconnected.tryEmit()
	}

	#onMessage(message: socketio.Message) {
		const listeners = this.#listeners.get(message.key) ?? []
		for (const listener of listeners) {
			try {
				listener(message.data as {})
			} catch (error) {
				console.error(error)
			}
		}
	}
}

export const socketIoSocket = new SocketIoSocket()
