import { readObjectProperty } from "./http"

export function logError(error: unknown) {
	console.error(readObjectProperty(error, "stack") ?? error)
	const cause = readObjectProperty(error, "cause")
	if (cause != undefined) {
		const full = `Caused by: ${cause}`
		logError(full)
	}
}
