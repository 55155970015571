export interface ContentProps {
	key: string
	w: number
	h: number
}

const createEmptyGrid = (cols: number, rows: number): boolean[][] => {
	return Array.from({ length: rows }, () => Array(cols).fill(false))
}

const fitsInGrid = (
	grid: boolean[][],
	col: number,
	row: number,
	colSpan: number,
	rowSpan: number
): boolean => {
	for (let r = 0; r < rowSpan; r++) {
		for (let c = 0; c < colSpan; c++) {
			const grid_0 = grid[0]
			const grid_rowPlusR = grid[row + r]
			if (
				row + r >= grid.length ||
				grid_0 === undefined ||
				col + c >= grid_0.length ||
				grid_rowPlusR === undefined ||
				grid_rowPlusR[col + c] === undefined ||
				grid_rowPlusR[col + c] === true
			) {
				return false
			}
		}
	}
	return true
}

const placeInGrid = (
	grid: boolean[][],
	col: number,
	row: number,
	colSpan: number,
	rowSpan: number
): void => {
	for (let r = 0; r < rowSpan; r++) {
		for (let c = 0; c < colSpan; c++) {
			const grid_rowPlusR = grid[row + r]
			if (grid_rowPlusR !== undefined) {
				grid_rowPlusR[col + c] = true
			}
		}
	}
}

export const divideIntoPages = (
	components: ContentProps[],
	cols: number,
	rows: number
): ContentProps[][] => {
	const pages: ContentProps[][] = []
	let currentPage: ContentProps[] = []
	let grid = createEmptyGrid(cols, rows)

	let currentRow = 0

	for (const component of components) {
		let placed = false
		for (let r = currentRow; r < rows; r++) {
			for (let c = 0; c < cols; c++) {
				if (fitsInGrid(grid, c, r, component.w, component.h)) {
					placeInGrid(grid, c, r, component.w, component.h)
					currentPage.push(component)
					placed = true
					// console.log(`Placed component ${component.key} at row ${r}, col ${c}`)
					currentRow = r
					break
				}
			}
			if (placed) break
		}
		if (!placed) {
			// console.log(`Starting new page for component ${component.key}`)
			pages.push(currentPage)
			currentPage = [component]
			grid = createEmptyGrid(cols, rows)
			placeInGrid(grid, 0, 0, component.w, component.h)
			currentRow = component.h
		}
	}

	if (currentPage.length > 0) {
		pages.push(currentPage)
	}

	return pages
}
