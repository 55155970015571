import { RequestStatus } from "@redux/app/RequestStatus"
import { RootState } from "@redux/app/store"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { requestUrlGetJson } from "@utils/http"
import { urls } from "api"

export type FishFarmOwner = {
	orgNo: number | null
	name: string
}

type State = {
	fishFarmOwners: FishFarmOwner[]
	selectedFishFarmOwner: FishFarmOwner
	status: RequestStatus
	error: string
}

const initialState: State = {
	fishFarmOwners: [],
	selectedFishFarmOwner: { orgNo: null, name: "" },
	status: RequestStatus.idle,
	error: "",
}

export const fetchFishFarmOwners = createAsyncThunk<FishFarmOwner[]>(
	"fishFarmOwners/fetchFishFarmOwners",
	async (): Promise<FishFarmOwner[]> => {
		return JSON.parse((await requestUrlGetJson(urls.GET_FISHFARM_OWNERS)) as string)
	}
)

const fishFarmOwnersSlice = createSlice({
	name: "fishFarmOwners",
	initialState,
	reducers: {
		setFishFarmOwners(state, action: PayloadAction<FishFarmOwner[]>) {
			return { ...state, fishFarmOwners: { ...action.payload } }
		},
		updateSelectedFishFarmOwner(state, action: PayloadAction<FishFarmOwner>) {
			return { ...state, selectedFishFarmOwner: { ...action.payload } }
		},
		clearSelectedFishFarmOwner(state) {
			return {
				...state,
				selectedFishFarmOwner: { orgNo: null, name: "" },
			}
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchFishFarmOwners.pending, (state, action) => {
				state.status = RequestStatus.loading
			})
			.addCase(fetchFishFarmOwners.fulfilled, (state, action) => {
				state.status = RequestStatus.succeeded
				state.fishFarmOwners = action.payload
			})
			.addCase(fetchFishFarmOwners.rejected, (state, action) => {
				state.status = RequestStatus.failed
				state.error = "" //action.error.message
			})
	},
})

export const getFishFarmOwners = (state: RootState) => state.fishFarmOwners.fishFarmOwners

export const getSelectedFishFarmOwner = (state: RootState) =>
	state.fishFarmOwners.selectedFishFarmOwner

export const getFishFarmOwnersStatus = (state: RootState) => state.fishFarmOwners.status

export const { setFishFarmOwners, updateSelectedFishFarmOwner, clearSelectedFishFarmOwner } =
	fishFarmOwnersSlice.actions

export default fishFarmOwnersSlice.reducer
