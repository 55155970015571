import { useGoogleLogin } from "@react-oauth/google"
import { useAppDispatch } from "@redux/app/hooks"
import { setUserData } from "@redux/features/userData/userDataSlice"
import { requestApi2 } from "@utils/http"
import { authEndpoints, LoginMethod } from "api"

export default function LoginGoogle() {
	const dispatch = useAppDispatch()

	const login = useGoogleLogin({
		onSuccess: async (tokenResponse) => {
			console.log("Login Success: currentUser:", tokenResponse)
			// refreshTokenSetup(res)
			try {
				const data = await requestApi2(authEndpoints.logIn, {
					method: LoginMethod.GOOGLE,
					token: tokenResponse.code,
				})
				if (data?.isAuthenticated === true) {
					dispatch(
						setUserData({
							name: data.name,
							email: data.email,
							isAuthenticated: data.isAuthenticated,
							profilePicture: data.profilePicture,
						})
					)
				} else {
					alert("Authentication filed. Please contact Dipai support for help.")
				}
			} catch (err) {
				console.log(err)
			}
		},
		flow: "auth-code",
		onError: (res) => {
			console.log("Login failed: res:", res)
			alert("Authentication filed. Contact Dipai support for help.")
		},
	})

	return (
		<button className="h-9 w-[201px] text-subheadline font-semibold" onClick={() => login()}>
			Sign in with Google
		</button>
	)
}
