export const ReportStyle = ({
	orientation = "portrait",
	marginTop = "50px",
	marginBottom = "50px",
	marginRight = "50px",
	marginLeft = "50px",
}: {
	orientation?: string
	marginTop?: string
	marginBottom?: string
	marginRight?: string
	marginLeft?: string
}) => {
	return (
		<style type="text/css">
			{`@media all {
                    .page-break {
                        display: none;
                    }
                }

                @media print {
                    html,
                    body {
                        height: initial !important;
                        overflow: initial !important;
                        -webkit-print-color-adjust: exact;
                    }
                }

                @media print {
                    .page-break {
                        margin-top: 1rem;
                        display: block;
                        page-break-after: always;
                        page-break-before: always;
                        break-before: auto;
                    }
                }
                
                @page { size: 1840px 2602px;  margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important;}`}
		</style>
	)
}
