import { logError } from "@utils/logError"
import {
	DataBatchArgs,
	issueToString,
	Message,
	MessageProcessor,
	MessageSpec,
	messageTypes,
	Notification,
} from "api"
import { ZodError } from "zod"
import { EventSource } from "../EventSource"
import { socketIoSocket } from "./socketIoSocket"

const DEFAULT_MSG = `Dear Dipai user,

The portal will now automatically re-start, due to a software update.

Best regards,
The Dipai developers`

export class UserWebSocket {
	private static reloadBusy = false
	private static serverWasStopped = false

	readonly notificationEvent = new EventSource<Notification>()
	readonly onDataBatch = new EventSource<DataBatchArgs>()
	readonly reloadEvent = new EventSource()

	readonly #messageProcessor = new MessageProcessor()

	constructor() {
		socketIoSocket.addMessageListener("legacyMessage", (m) =>
			this.#parseMessage(m.payload).catch(logError)
		)
		this.#messageProcessor //
			.addCallback(messageTypes.notification, async (n) => {
				this.notificationEvent.emit(n)
			})
			.addCallback(messageTypes.reload, async () => {
				if (!UserWebSocket.reloadBusy) {
					UserWebSocket.reloadBusy = true
					alert(DEFAULT_MSG)
					UserWebSocket.reloadBusy = false
					window.location.reload()
				}
			})
			.addCallback(messageTypes.serverStopping, async () => {
				UserWebSocket.serverWasStopped = true
			})
			.addCallback(messageTypes.dataBatch, async (m) => this.onDataBatch.emit(m))
		this.#enablePortalAutoReload()
	}

	send<T>(spec: MessageSpec<T>, data: T) {
		const json = JSON.stringify(this.#messageProcessor.serialize(spec, data))
		if (socketIoSocket.isConnected) {
			socketIoSocket.sendMessage("legacyMessage", { payload: json })
		}
	}

	#enablePortalAutoReload() {
		socketIoSocket.onDisconnected.addListener(() => {
			if (UserWebSocket.serverWasStopped) {
				console.log("Reload message should occur")
				const userConfirmsReload = confirm(
					"Dear Dipai user,\n\nThe portal has been re-started, due to a software update.\n\nPlease click OK to reload the portal."
				)
				if (userConfirmsReload == true) {
					window.location.reload()
				}
			}
			UserWebSocket.serverWasStopped = false
		})
	}

	async #parseMessage(data: string) {
		try {
			const message = Message.parse(JSON.parse(data))
			await this.#messageProcessor.parse(message)
		} catch (err) {
			if (err instanceof ZodError) {
				let errorMessage = `The type validator found an error with the data.`
				for (const issue of err.issues) {
					errorMessage += `\n${issueToString(issue)}`
				}
				throw new Error(errorMessage, { cause: err })
			}
			throw err
		}
	}
}
