import { ReactElement } from "react"
import LoginGoogle from "./google/LoginGoogle"
import LoginMicrosoft from "./microsoft/LoginMicrosoft"

export default function LoginPage(): ReactElement {
	return (
		<div className="h-[100vh] bg-dipai-secondary-901 text-white ">
			<div className="grid h-24 grid-cols-2">
				<img className="ml-12 mt-9 h-7" src="/assets/img/logos/Dipai.png" alt="Dipai AS" />
				<div className="relative">
					<div className="absolute right-12 mt-12 text-tiny">
						Problems? Please contact <span className="underline">Dipai Support</span>.
					</div>
				</div>
			</div>
			<div className="m-auto w-[250px]" style={{ marginTop: "calc(50vh - 96px - 260px)" }}>
				<p className="m-auto mb-[55px] h-[49px] w-[250px] text-center text-title2">
					Sign in to Dipai
				</p>
			</div>

			<div className="m-auto flex h-9 w-[250px] overflow-hidden rounded-lg bg-dipai-primary-501 text-black">
				<div className="h-9 w-11 bg-white">
					<img
						className="ml-[13px] mt-[9px] w-[18px]"
						src="/assets/img/icons/microsoft_icon.png"
						alt=""
					/>
				</div>
				<LoginMicrosoft />
			</div>

			<p className="m-auto my-[30px] w-[250px] text-center text-subheadline"> - or - </p>

			<div className="m-auto flex h-9 w-[250px] overflow-hidden rounded-lg bg-dipai-primary-501 text-black">
				<div className="h-9 w-11 bg-white">
					<img
						className="ml-[13px] mt-[9px] w-[18px]"
						src={"/assets/img/icons/google_icon.png"}
						alt=""
					/>
				</div>
				<LoginGoogle />
			</div>
		</div>
	)
}
