import { RequestStatus } from "@redux/app/RequestStatus"
import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type State = {
	companyName: string | undefined
	status: RequestStatus
	error: string
}

const initialState: State = {
	companyName: undefined,
	status: RequestStatus.idle,
	error: "",
}

const currentCompanySlice = createSlice({
	name: "selectedVessel",
	initialState,
	reducers: {
		setCurrentCompany(state, action: PayloadAction<string | undefined>) {
			return { ...state, companyName: action.payload }
		},
		clearCurrentCompany(state) {
			return { ...state, ...initialState }
		},
	},
})

export const getCurrentCompany = (state: RootState) => state.currentCompany.companyName

export const { setCurrentCompany, clearCurrentCompany } = currentCompanySlice.actions

export default currentCompanySlice.reducer
