import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type MapFiltersPanelState = {
	isOpen: boolean
}

export const defaultState: MapFiltersPanelState = {
	isOpen: true,
}

const storedState = localStorage.getItem("showFiltersPanel")
export const initialState: MapFiltersPanelState =
	storedState !== null ? JSON.parse(storedState) : defaultState

const mapFiltersPanelStateSlice = createSlice({
	name: "mapFiltersPanelState",
	initialState,
	reducers: {
		updateMapFiltersPanelState(state, action: PayloadAction<MapFiltersPanelState>) {
			localStorage.setItem("showFiltersPanel", JSON.stringify(action.payload))
			return { ...state, ...action.payload }
		},
		resetMapFiltersPanelState(state) {
			localStorage.setItem("showFiltersPanel", JSON.stringify(defaultState))
			return { ...state, ...defaultState }
		},
	},
})

export const getMapFiltersPanelState = (state: RootState) => state.mapFiltersPanelState
export const { updateMapFiltersPanelState, resetMapFiltersPanelState } =
	mapFiltersPanelStateSlice.actions
export default mapFiltersPanelStateSlice.reducer
