import { formatDate } from "@helpers/formatDate"
import {
	Dispatch,
	FC,
	ReactElement,
	SetStateAction,
	cloneElement,
	forwardRef,
	isValidElement,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react"
import { useReactToPrint } from "react-to-print"
import { ContentWrapper, IContentWrapperProps, findSpanOutOf60 } from "./ContentWrapper"
import {
	IReportGeneratorTrigger,
	ReportGeneratorTrigger,
	ReportGeneratorTriggerOnLoading,
} from "./ReportGeneratorTrigger"
import { IReportPageBodyProps } from "./ReportPageBody"
import { IReportPageFooterProps } from "./ReportPageFooterDipai"
import { IReportPageHeaderProps } from "./ReportPageHeaderDipai"
import { ReportStyle } from "./ReportStyle"
import { ContentProps, divideIntoPages } from "./reportGeneratorUtils"

export interface IReportGeneratorProps {
	children: ReactElement<ReactElementExtended>[]
	customerLogo: JSX.Element
	dipaiLogo: JSX.Element
	pdfTitle?: string
	reportName: string
	dateFrom: string
	dateTo: string
	listOfVessels: string[]
	isDisabled?: boolean
}

interface IGenericReportGeneratorProps extends IReportGeneratorProps {
	ReportPageBody: FC<IReportPageBodyProps>
	ReportPageHeader: FC<IReportPageHeaderProps>
	ReportPageFooter: FC<IReportPageFooterProps>
}

export interface ReactElementExtended {
	[key: string]: unknown
	exposeIsLoaded?: Dispatch<
		SetStateAction<
			{
				idKey: string
				isLoaded: boolean | undefined
			}[]
		>
	>
	idKey?: string
}
const ReportGenerator = forwardRef<HTMLDivElement, IGenericReportGeneratorProps>((props, ref) => {
	// MISSING LOGIC TO SCALE COMPONENTS
	const [itemsLoaded, setItemsLoaded] = useState<
		{ idKey: string; isLoaded: boolean | undefined }[]
	>([])

	const isLoadingRef = useRef(itemsLoaded)

	useEffect(() => {
		isLoadingRef.current = itemsLoaded
	}, [itemsLoaded])

	const [components, setComponents] = useState<JSX.Element[]>([])
	const [isDataLoading, setIsDataLoading] = useState<boolean>(false)

	const [itemsSizes, setItemsSizes] = useState<ContentProps[]>([])
	const [TriggerElement, setTriggerElement] = useState<ReactElement<IReportGeneratorTrigger>>()
	const [TriggerOnLoadingElement, setTriggerOnLoading] =
		useState<ReactElement<IReportGeneratorTrigger>>()

	useEffect(() => {
		const itemsLoadedTemp: { idKey: string; isLoaded: boolean | undefined }[] = []
		const itemsSizesTemp: ContentProps[] = []
		const componentsTemp: JSX.Element[] = []
		props.children.forEach((child) => {
			if (isValidElement(child)) {
				if (child.type !== ContentWrapper) {
					if (child.type === ReportGeneratorTrigger) {
						setTriggerElement(child as ReactElement<IReportGeneratorTrigger>)
					} else if (child.type === ReportGeneratorTriggerOnLoading) {
						setTriggerOnLoading(child as ReactElement<IReportGeneratorTrigger>)
					} else {
						console.error(
							"All children of MainComponent must be wrapped in a ContentWrapper components."
						)
					}
				} else {
					const {
						idKey,
						rowSpan: rowPercentage,
						colSpan: colPercentage,
						children: childChildren,
					} = child.props as unknown as IContentWrapperProps
					if (childChildren !== null && childChildren !== undefined) {
						itemsLoadedTemp.push({ isLoaded: undefined, idKey })
						const newChild = cloneElement(
							childChildren as ReactElement<ReactElementExtended>,
							{
								exposeIsLoaded: setItemsLoaded,
								idKey,
							}
						)

						const newWrapper = cloneElement(child as ReactElement, {
							children: newChild,
						})
						componentsTemp.push(newWrapper)
						itemsSizesTemp.push({
							key: idKey,
							w: findSpanOutOf60(colPercentage),
							h: findSpanOutOf60(rowPercentage),
						})
					}
				}
			}
		})
		setComponents(componentsTemp)
		setItemsLoaded(itemsLoadedTemp)
		setItemsSizes(itemsSizesTemp)
	}, [props.children])
	// PRINTING
	const componentRef = useRef<HTMLDivElement>(null)

	const onBeforeGetContentResolve = useRef<unknown>(null)
	const [isRendered, setIsRendered] = useState<boolean>(false)

	const handleOnBeforeGetContent = async () => {
		// setLoading(true)
		// setText("Loading new text...")
		const ref = componentRef.current
		if (ref === null) {
			return
		}
		setIsDataLoading(true)
		setIsRendered(true)
		await new Promise<void>((resolve, reject) => {
			setTimeout(() => {
				onBeforeGetContentResolve.current = resolve

				let attempts = 0
				const maxAttempts = 1000000

				const checkIfLoaded = () => {
					if (
						isLoadingRef.current.map((i) => i.isLoaded).includes(false) === false &&
						isLoadingRef.current.map((i) => i.isLoaded).includes(true) === true
					) {
						resolve()
					} else if (attempts < maxAttempts) {
						attempts += 1
						setTimeout(checkIfLoaded, 250)
					} else {
						setIsRendered(false)
						setIsDataLoading(false)
						console.error(`Loading timed out after ${maxAttempts} attempts`)
						alert("Cannot print because there was an error loading the content.")
						reject(
							new Error(
								"Cannot print because there was an error loading the content."
							)
						)
					}
				}
				checkIfLoaded()
			}, 1000)
		})
	}

	const handleBeforePrint = useCallback(() => {}, [])

	const handleAfterPrint = useCallback(() => {
		// setLoading(true)
		// setText("Loading new text...")
		const ref = componentRef.current
		if (ref === null) {
			return
		}
		return new Promise((resolve: (value: void) => void) => {
			setItemsLoaded((prev) =>
				prev.map((i) => {
					return { ...i, isLoaded: undefined }
				})
			)
			setIsRendered(false)
			setIsDataLoading(false)
			resolve()
		})
	}, [])

	const reactToPrintContent = useCallback(() => {
		return componentRef.current
	}, [componentRef.current])

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: props.pdfTitle,
		nonce: undefined,
		onBeforeGetContent: handleOnBeforeGetContent,
		onBeforePrint: handleBeforePrint,
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	})

	const [contentsByPage, setContentsByPage] = useState<JSX.Element[][]>()

	useEffect(() => {
		const pages = divideIntoPages(itemsSizes, 60, 60)
		const pageBreakKeys: string[] = []
		pages.forEach((p, idx) => idx > 0 && p[0] !== undefined && pageBreakKeys.push(p[0].key))

		let refIdx = 0
		const arrayWithEmptyArrays: JSX.Element[][] = Array.from(
			{ length: pageBreakKeys.length + 1 },
			() => []
		)
		const result = components.reduce((acc, item) => {
			const { idKey } = item.props as IContentWrapperProps
			if (idKey === pageBreakKeys[refIdx]) {
				refIdx++
			}
			if (
				itemsLoaded.map((i) => i.isLoaded).includes(true) &&
				itemsLoaded.find((i) => i.idKey === idKey)?.isLoaded === undefined
			) {
				const itemWithError = cloneElement(item as ReactElement, {
					children: (
						<div className={`h-full w-full bg-slate-200`}>
							{
								"Error: Component does not contain all the necessary logic for printing"
							}
						</div>
					),
				})
				acc[refIdx]?.push(itemWithError)
			} else {
				acc[refIdx]?.push(item)
			}
			return acc
		}, arrayWithEmptyArrays)
		setContentsByPage(result)
	}, [itemsLoaded]) //,components, itemsSizes])

	const dateStrLocal = formatDate(new Date(), "DD/MM/YYYY - HH:MM")

	return (
		<>
			{contentsByPage === undefined ? null : (
				<div className="absolute -left-[100000px] top-[150px]">
					<div ref={componentRef} className="h-auto w-[1800px] flex-col overflow-hidden">
						{isRendered === false ? null : (
							<>
								<ReportStyle marginTop={"25px"} marginBottom={"25px"} />
								{/* PAGE ELEMENT */}
								{contentsByPage.map((pageContent, idx) => (
									<div
										key={`reportPage_${idx}`}
										className={`relative flex h-[calc(2604px)] flex-col gap-3`}
										style={{
											pageBreakBefore: idx > 0 ? "always" : "auto",
											breakBefore: idx > 0 ? "page" : "auto",
											pageBreakAfter: "always",
										}}
									>
										{/* REFERENCE LINE */}
										{/* <div className="absolute bottom-0 right-[200px] z-[1] flex h-[100%] w-4 origin-[left_100px] translate-y-0 rotate-[5deg] flex-row ">
									<div className={`w-1`} />
									<div className={`w-1 bg-dipai-primary-501`} />
									<div className={`w-2 bg-dipai-secondary-901`} />
								</div> */}
										{/* HEADER ELEMENT */}
										<props.ReportPageHeader
											customerLogo={props.customerLogo}
											dipaiLogo={props.dipaiLogo}
											reportName={props.reportName}
											dateGenerated={dateStrLocal}
											dateFrom={props.dateFrom}
											dateTo={props.dateTo}
											listOfVessels={props.listOfVessels}
										/>
										{/* BODY ELEMENT  */}
										<props.ReportPageBody>{pageContent}</props.ReportPageBody>
										{/* FOOTER ELEMENT  */}
										<props.ReportPageFooter
											currentPage={idx + 1}
											totalPages={contentsByPage.length}
										/>
									</div>
								))}
							</>
						)}
					</div>
				</div>
			)}
			{TriggerOnLoadingElement !== undefined && isDataLoading === true
				? TriggerOnLoadingElement
				: null}
			{TriggerElement !== undefined && isDataLoading === false
				? cloneElement(TriggerElement, {
						onClick: props.isDisabled !== true ? handlePrint : undefined,
				  })
				: null}
		</>
	)
})

const ReportGeneratorNamespace = Object.assign(ReportGenerator, {
	ContentWrapper: ContentWrapper,
	Trigger: ReportGeneratorTrigger,
	TriggerOnLoading: ReportGeneratorTriggerOnLoading,
})
export { ReportGeneratorNamespace as ReportGenerator }
