import { ChartConfiguration, Chart as ChartJS, ChartType, LegendItem } from "chart.js"

declare module "chart.js" {
	interface PluginOptionsByType<TType extends ChartType> {
		htmlLegend?: {
			containerID?: string
			textSize?: number
		}
	}
}

const getOrCreateLegendList = (chart: ChartJS, id: string): HTMLUListElement | undefined => {
	const legendContainer = document.getElementById(id)
	if (legendContainer === null) {
		return
	}

	let listContainer = legendContainer.querySelector("ul") as HTMLUListElement | null

	if (listContainer === null) {
		listContainer = document.createElement("ul")
		listContainer.style.display = "flex"
		listContainer.style.flexDirection = "row"
		listContainer.style.margin = "0"
		listContainer.style.padding = "0"

		legendContainer.appendChild(listContainer)
	}

	return listContainer
}

export const htmlLegendPlugin = {
	id: "htmlLegend",
	afterUpdate(
		chart: ChartJS,
		args: { cancelable: true },
		options: { containerID: string; textSize?: number }
	) {
		if (options.containerID === undefined) {
			return
		}

		const ul = getOrCreateLegendList(chart, options.containerID)

		if (ul !== undefined) {
			// Remove old legend items
			while (ul.firstChild !== null) {
				ul.firstChild.remove()
			}
			ul.style.display = "flex"
			ul.style.flexDirection = "column"
			ul.style.overflow = "hidden"
			//ul.style.flexWrap = "wrap"

			// Reuse the built-in legendItems generator
			const items = chart.options.plugins?.legend?.labels
			if (items !== undefined && typeof items.generateLabels === "function") {
				const labels = items.generateLabels(chart) as LegendItem[]

				labels.forEach((item: LegendItem) => {
					const li = document.createElement("div")
					li.style.alignItems = "center"
					li.style.cursor = "pointer"
					li.style.display = "flex"
					li.style.flexDirection = "row"
					li.style.marginLeft = "20px"

					li.onclick = () => {
						const { type } = chart.config as ChartConfiguration
						if (type === "pie" || type === "doughnut") {
							chart.toggleDataVisibility(item.index ?? -1)
						} else {
							chart.setDatasetVisibility(
								item.datasetIndex ?? -1,
								!chart.isDatasetVisible(item.datasetIndex ?? -1)
							)
						}
						chart.update()
					}

					// Color box
					const boxSpan = document.createElement("span")
					boxSpan.style.background = `${item.fillStyle}`
					boxSpan.style.borderColor = `${item.strokeStyle}`
					boxSpan.style.borderWidth = item.lineWidth + "px"
					boxSpan.style.display = "inline-block"
					boxSpan.style.flexShrink = "0"
					boxSpan.style.height =
						options.textSize !== undefined ? `${options.textSize}px` : "12px"
					boxSpan.style.marginRight = "5px"
					boxSpan.style.width =
						options.textSize !== undefined ? `${options.textSize}px` : "40px"

					// Text
					const textContainer = document.createElement("p")
					textContainer.style.whiteSpace = "nowrap"
					textContainer.style.overflow = "hidden"
					textContainer.style.textOverflow = "ellipsis"
					textContainer.style.color = `${item.fontColor}`
					textContainer.style.margin = "0"
					textContainer.style.padding = "0"
					textContainer.style.fontSize =
						options.textSize !== undefined ? `${options.textSize}px` : "12px"
					textContainer.style.textDecoration = item.hidden ?? false ? "line-through" : ""

					const text = document.createTextNode(item.text)
					textContainer.appendChild(text)

					li.appendChild(boxSpan)
					li.appendChild(textContainer)
					ul.appendChild(li)
				})
			}
		}
	},
}
