import { useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import { FC } from "react"
import { Link, useLocation } from "react-router-dom"
import dipai_D from "../../../assets/img/logos/DipaiLogo_D_White.svg"
import dipai_dot from "../../../assets/img/logos/DipaiLogo_Dot_Yellow.svg"
import dipai_IPAI from "../../../assets/img/logos/DipaiLogo_IPAI_White.svg"
import { usePageLayoutContext } from "../PageLayout"

export const DipaiLogoHeader = ({
	isExpanded,
	selectedModule,
	width = 180,
}: {
	isExpanded: boolean
	selectedModule: string
	width?: number
}) => {
	return (
		<div
			className={`w-[158px] ${
				isExpanded === false ? `delay-500 sm:w-[60px]` : `delay-0 duration-0 sm:w-[180px]`
			} relative flex h-[52px] items-center border-b-1 border-b-dipai-secondary-901 border-opacity-40 pl-[18px] ${
				selectedModule.toLowerCase() !== `fleet overview`
					? `shadow-[0px_1.5px_1px_hsla(0,0%,100%,0.15)]`
					: `shadow-[0px_1.5px_1px_hsla(0,0%,0%,0.15)]`
			}`}
			style={{ width: width }}
		>
			<img className={`h-[32.5px]`} alt={"D"} src={dipai_D} />
			<img
				className={`h-[32.5px] origin-left transition-all duration-300 ${
					isExpanded === true ? `w-[59.75px] opacity-100` : `w-0 opacity-0`
				}`}
				alt={"ipai"}
				src={dipai_IPAI}
			/>
			<img
				className={`absolute h-[32.5px] transition-all duration-300 ${
					isExpanded === true
						? `left-[calc(21.9px+18px+59.75px)]`
						: `left-[calc(21.9px+18px)]`
				}`}
				alt={"."}
				src={dipai_dot}
			/>
		</div>
	)
}
export const MainNavHeader: FC = () => {
	const location = useLocation()
	const appStructure = useAppSelector(getAppStructure)
	const {
		selectedModule,
		isMainNavExpanded,
		isMainNavPopupExpanded,
		isSecondaryNavExpanded,
		setIsMainNavPopupExpanded,
	} = usePageLayoutContext()

	return (
		<>
			<div className={`hidden w-full lg:flex`}>
				{location.pathname.includes("/clients") === true &&
				appStructure !== null &&
				Object.keys(appStructure)[0] === "Dipai" ? (
					<Link to={"/clients"}>
						<DipaiLogoHeader
							selectedModule={selectedModule}
							isExpanded={isMainNavExpanded}
						/>
					</Link>
				) : (
					<DipaiLogoHeader
						selectedModule={selectedModule}
						isExpanded={isMainNavExpanded}
					/>
				)}
			</div>
			<div className={`flex w-full sm:flex lg:hidden`}>
				{location.pathname.includes("/clients") === true &&
				appStructure !== null &&
				Object.keys(appStructure)[0] === "Dipai" ? (
					<Link to={"/clients"}>
						<DipaiLogoHeader
							selectedModule={selectedModule}
							isExpanded={isMainNavPopupExpanded}
						/>
					</Link>
				) : (
					<DipaiLogoHeader
						selectedModule={selectedModule}
						isExpanded={isMainNavPopupExpanded}
					/>
				)}
			</div>
			{/* <div className={`flex w-full sm:hidden`}>
				{location.pathname.includes("/clients") === true &&
				appStructure !== null &&
				Object.keys(appStructure)[0] === "Dipai" ? (
					<div
						className={`flex origin-left flex-row items-center transition-[width] duration-300 ${
							isSecondaryNavExpanded === true ? "w-[212px]" : "w-[52px]"
						}`}
					>
						<MdMenu
							onClick={() => {
								setIsMainNavPopupExpanded(true)
							}}
							className={`mx-2 h-9 w-9 cursor-pointer rounded-sm px-1 text-white text-opacity-60 hover:bg-white hover:bg-opacity-10 hover:text-opacity-100`}
						/>
						{isSecondaryNavExpanded === true ? (
							<Link to={"/clients"}>
								<div className={``}>
									<DipaiLogoHeader
										selectedModule={selectedModule}
										isExpanded={isSecondaryNavExpanded}
									/>
								</div>
							</Link>
						) : null}
					</div>
				) : (
					<div className={`flex flex-row items-center`}>
						<MdMenu
							className={`h-8 w-8 text-white hover:bg-white hover:bg-opacity-10`}
							onClick={() => {
								setIsMainNavPopupExpanded(true)
							}}
						/>
						<DipaiLogoHeader
							selectedModule={selectedModule}
							isExpanded={isSecondaryNavExpanded}
						/>
					</div>
				)}
			</div> */}
		</>
	)
}
