import * as msgpack from "@msgpack/msgpack"
import { EndpointSpec, parseData, parsingErrorToString, toZodError } from "api"
import { logError } from "./logError"

export function readObjectProperty(object: unknown, property: string): unknown {
	if (typeof object == "object" && object != null && property in object) {
		return object[property]
	}
}

/**
 * @deprecated Use {@link requestApi2}
 */
export async function requestApi<I, O>(
	spec: EndpointSpec<I, O>,
	args?: {} & I,
	abortSignal?: AbortSignal
): Promise<O> {
	if (spec.name == undefined) {
		throw new Error(`Internal error: endpoint name is missing`)
	}
	const url = spec.name
	if (spec.resultParser == null) {
		await requestUrl(url, args, abortSignal)
		return null as O
	}
	const rawData = await requestUrlGetJson(url, args, abortSignal)
	try {
		return parseData(spec.resultParser, rawData)
	} catch (err) {
		console.error(parsingErrorToString(url, err))
		console.error(rawData)
		return rawData as O
	}
}

/**
 * Makes a request to the server, returns null if an error is detected
 * @param spec
 * @param args
 * @param abortSignal
 * @returns
 */
export async function requestApi2<I, O>(
	spec: EndpointSpec<I, O>,
	args?: {} & I,
	abortSignal?: AbortSignal
): Promise<O | null> {
	try {
		return await requestApi2_getError(spec, args, abortSignal)
	} catch (err) {
		logError(err)
	}
	return null
}

export async function requestApi2_getError<I, O>(
	spec: EndpointSpec<I, O>,
	args?: {} & I,
	abortSignal?: AbortSignal
): Promise<O | null> {
	if (spec.name == undefined) {
		throw new Error(`Internal error: endpoint name is missing`)
	}
	const url = spec.name
	if (spec.resultParser == null) {
		await requestUrl(url, args, abortSignal)
		return null
	}
	const rawData = await requestUrlGetJson(url, args, abortSignal)
	try {
		return parseData(spec.resultParser, rawData)
	} catch (err) {
		throw toZodError(err) ?? err
	}
}

/**
 * Makes a request to the server, but encodes the payload in binary instead of
 * JSON. Returns null if an error is detected
 * @param spec
 * @param args
 * @param abortSignal
 * @returns
 */
export async function requestApiWithBinaryPayload<I, O>(
	spec: EndpointSpec<I, O>,
	args?: {} & I,
	abortSignal?: AbortSignal
): Promise<O | null> {
	try {
		if (spec.name == undefined) {
			throw new Error(`Internal error: endpoint name is missing`)
		}
		const url = spec.name
		const response = await fetch(url, {
			signal: abortSignal,
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			headers: { "content-type": "application/json", accept: "application/msgpack" },
			body: JSON.stringify(args ?? {}),
		})
		if (!response.ok) {
			let text = await response.text()
			if (text == "") {
				text = `A request failed: ${url}\nStatus: ${response.statusText}`
			}
			throw new Error(text)
		}
		const payload = await response.arrayBuffer()
		const data = msgpack.decode(payload)
		return data as O
	} catch (err) {
		logError(err)
	}
	return null
}

export async function requestUrl(
	url: string,
	args?: unknown,
	abortSignal?: AbortSignal
): Promise<Response> {
	const response = await fetch(url, {
		signal: abortSignal,
		method: "POST",
		mode: "cors",
		cache: "no-cache",
		credentials: "same-origin",
		headers: { "content-type": "application/json" },
		body: JSON.stringify(args ?? {}),
	})
	if (!response.ok) {
		let text = await response.text()
		if (text == "") {
			text = `A request failed: ${url}\nStatus: ${response.statusText}`
		}
		throw new Error(text)
	}
	return response
}

export async function requestUrlGetJson(
	url: string,
	args?: unknown,
	abortSignal?: AbortSignal
): Promise<unknown> {
	const response = await requestUrl(url, args, abortSignal)
	return await response.json()
}
