import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Filler,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	TimeScale,
	Title,
	Tooltip,
} from "chart.js"
import "chartjs-adapter-date-fns"
import annotationPlugin from "chartjs-plugin-annotation"
import zoomPlugin from "chartjs-plugin-zoom"
import { bgColorPlugin } from "./plugins/bgColorPlugin"
import { bgHeatmapPlugin } from "./plugins/bgHeatmapPlugin"
import { crosshairV2 } from "./plugins/crosshair"
import { customCanvasBackgroundColorPlugin } from "./plugins/customCanvasBackgroundColor"
import { customLineTooltipPlugin } from "./plugins/customLineTooltip"
import { htmlLegendPlugin } from "./plugins/htmlLegend"
import { verticalLine } from "./plugins/verticalLine"

ChartJS.register(
	annotationPlugin,
	ArcElement,
	BarElement,
	CategoryScale,
	Filler,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	TimeScale,
	Title,
	Tooltip,
	zoomPlugin,
	// CUSTOM PLUGINS
	bgColorPlugin,
	crosshairV2,
	customCanvasBackgroundColorPlugin,
	customLineTooltipPlugin,
	htmlLegendPlugin,
	bgHeatmapPlugin,
	verticalLine
)
