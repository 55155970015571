import { RootState } from "@redux/app/store"
import { FishFarmOwner } from "@redux/features/fishFarmOwners/fishFarmOwnersSlice"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type liceThreshold = {
	threshold: number
	showSitesBelowThreshold: boolean
	showSitesAboveThreshold: boolean
}

type State = {
	showProductionSites: boolean
	liceThreshold: liceThreshold
	reportingStatus: string
	selectedFishFarmOwner: FishFarmOwner
}

export const defaultState: State = {
	showProductionSites: true,
	liceThreshold: {
		threshold: 0.45,
		showSitesBelowThreshold: true,
		showSitesAboveThreshold: true,
	},
	reportingStatus: "0",
	selectedFishFarmOwner: { orgNo: null, name: "" },
}

const storedState = localStorage.getItem("mapFilters")
export const initialState: State = storedState !== null ? JSON.parse(storedState) : defaultState

const mapFiltersSlice = createSlice({
	name: "mapFilters",
	initialState,
	reducers: {
		updateMapFilters(state, action: PayloadAction<State>) {
			localStorage.setItem("mapFilters", JSON.stringify(action.payload))
			return { ...state, ...action.payload }
		},
		updateSelectedFishFarmOwner(state, action: PayloadAction<FishFarmOwner>) {
			return { ...state, selectedFishFarmOwner: { ...action.payload } }
		},
		resetMapFilters(state) {
			localStorage.setItem("mapFilters", JSON.stringify(defaultState))
			return { ...state, ...defaultState }
		},
	},
})

export const { updateMapFilters, resetMapFilters, updateSelectedFishFarmOwner } =
	mapFiltersSlice.actions

export const getMapFilters = (state: RootState) => state.mapFilters
export const getSelectedFishFarmOwner = (state: RootState) => state.mapFilters.selectedFishFarmOwner
export default mapFiltersSlice.reducer
