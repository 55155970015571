import { RequestStatus } from "@redux/app/RequestStatus"
import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { BunkerReport, BunkerReportEntry, BunkerReportFuelType } from "api"
import { BunkerReportEntryFE, initData } from "./defaultData"

type WithUndefinedId<T> = {
	[P in keyof T]: T[P] extends number ? T[P] | undefined : T[P]
}
type FuelTypeOrString<T> = {
	[P in keyof T]: T[P] extends BunkerReportFuelType ? T[P] | string : T[P]
}
type WithUnselectedFuelType<T> = {
	[P in keyof T]: T[P] extends BunkerReportEntry ? FuelTypeOrString<T[P]> : T[P]
}
export type BunkerReportFE = Omit<WithUndefinedId<BunkerReport>, "data"> & {
	data: BunkerReportEntryFE
}

export const INIT_FORM_STATE: BunkerReportFE = {
	id: undefined,
	timestamp: "",
	data: initData,
	plantId: -1,
}

type SelectedBunkerReport = {
	bunkerReport: BunkerReportFE
	showForm: boolean
	status: RequestStatus
	error: string
}

const initialState: SelectedBunkerReport = {
	bunkerReport: INIT_FORM_STATE,
	showForm: false,
	status: RequestStatus.idle,
	error: "",
}

const selectedBunkerReport = createSlice({
	name: "selectedBunkerReport",
	initialState,
	reducers: {
		setSelectedBunkerReport(state, action: PayloadAction<BunkerReportFE>) {
			return { ...state, bunkerReport: { ...state.bunkerReport, ...action.payload } }
		},
		setShowBunkerReportForm(state, action: PayloadAction<boolean>) {
			return { ...state, showForm: action.payload }
		},
		clearSelectedBunkerReport(state) {
			return { ...state, bunkerReport: INIT_FORM_STATE }
		},
	},
})

export const getSelectedBunkerReport = (state: RootState) => state.selectedBunkerReport.bunkerReport

export const getBunkerReportFormState = (state: RootState) => state.selectedBunkerReport.showForm

export const { setSelectedBunkerReport, clearSelectedBunkerReport, setShowBunkerReportForm } =
	selectedBunkerReport.actions

export default selectedBunkerReport.reducer
