import clsx, { ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

export function debounce<T extends (...args: Parameters<T>) => ReturnType<T>>(fn: T, ms = 300) {
	let timeoutId: ReturnType<typeof setTimeout> | undefined

	return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
		if (timeoutId !== undefined) {
			clearTimeout(timeoutId)
		}
		timeoutId = setTimeout(() => fn.apply(this, args), ms)
	}
}
