import { RequestStatus } from "@redux/app/RequestStatus"
import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PathDataEntry } from "@utils/map/routeTracer/routeTracer"

type State = {
	routeData: PathDataEntry[]
	status: RequestStatus
	error: string
}

const initialState: State = {
	routeData: [],
	status: RequestStatus.idle,
	error: "",
}

const routeTracerDataSlice = createSlice({
	name: "routeTracerData",
	initialState,
	reducers: {
		setRouteTracerData(state, action: PayloadAction<PathDataEntry[]>) {
			return { ...state, routeData: action.payload }
		},
		clearRouteTracerData(state) {
			return { ...state, ...initialState }
		},
	},
})

export const getRouteTracerData = (state: RootState) => state.routeTracerData.routeData
export const getRouteTracerDataStatus = (state: RootState) => state.routeTracerData.status

export const { setRouteTracerData } = routeTracerDataSlice.actions

export default routeTracerDataSlice.reducer
