import { DetailedHTMLProps, FC, Fragment, HTMLAttributes, ReactNode } from "react"

interface SecondaryNavItemProps
	extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	isSelected: boolean
	label: string
	icons?: ReactNode[]
}

export const SecondaryNavItem: FC<SecondaryNavItemProps> = (props: SecondaryNavItemProps) => {
	const { label, isSelected, icons, ...rest } = props
	return (
		<div
			onMouseDown={(e) => {
				const elem = document.activeElement as HTMLElement | null
				elem?.blur?.()
				e.preventDefault()
				props.onClick
			}}
			className={`focus-style flex h-[32px] w-full flex-row items-center justify-between overflow-x-clip whitespace-nowrap rounded-sm px-2 text-left text-button font-semibold ring-inset hover:bg-black hover:bg-opacity-20 
            ${
				isSelected === true
					? `cursor-default bg-white bg-opacity-10 text-dipai-primary-501 text-opacity-100`
					: `cursor-pointer text-white text-opacity-60 hover:text-white group-hover:text-opacity-100`
			} `}
			{...rest}
		>
			{label}
			{icons !== undefined ? (
				<div className={`flex flex-row justify-end gap-2`}>
					{icons.map((icon, index) => (
						<Fragment key={`icon_${index}`}>{icon}</Fragment>
					))}
				</div>
			) : null}
		</div>
	)
}
