export const initData = {
	// Freight letter info
	freightLetter: {
		startedDato: "",
		submittedDato: "",
		id: "",
	},
	// Vessel info
	vessel: {
		name: "",
		shipNumber: "",
		phone: "",
		email: "",
	},
	// Asssignment start
	assignmentStart: {
		date: "",
		time: "",
	},
	// Shipper information
	shipperInfo: {
		shipperName: "",
		loadingLocation: "",
		loadingLocationNumber: "",
		arrival: {
			date: "",
			time: "",
		},
		comments: "",
	},
	// Assignment information
	assignment: {
		operation: {
			operationType: "",
			specicies: "",
		},
		seaTemp: "",
		breeder: "",
		globalGapCertNumber: "",
		crowdingTime: "00:00",
		approvedTransportRouteAvailable: false,
		passingThroughAreaWithDisease: false,
		completedRiskAssessment11: false,
		informedOfHealthConditionsByBreeder: false,
		diseaseRestrictionsReceived: false,
		shipCleaned: false,
		comments: "",
	},
	// Loading
	loading: {
		start: {
			date: "",
			time: "",
		},
		complete: {
			date: "",
			time: "",
		},
		comments: "",
	},
	// Well
	wells: {
		well1: { numberFish: 0, avgWeight: 0, destination: "" },
		well2: { numberFish: 0, avgWeight: 0, destination: "" },
		well3: { numberFish: 0, avgWeight: 0, destination: "" },
		well4: { numberFish: 0, avgWeight: 0, destination: "" },
	},
	// Recipient information
	recipientInfo: {
		recipientName: "",
		recipientLocation: "",
		recipientLocationNumber: "",
		arrival: {
			date: "",
			time: "",
		},
		comments: "",
	},
	// Unloading
	unloading: {
		start: {
			date: "",
			time: "",
		},
		complete: {
			date: "",
			time: "",
		},
		uv: {
			yes: false,
			hours: "00:00",
		},
		liceFiltering: {
			yes: false,
			hours: "00:00",
		},
		roWaterProduced: "",
		numberDeadFish: "",
		comments: "",
	},
	// Asssignment end
	assignmentEnd: {
		date: "",
		time: "",
	},
}

export const initData2 = {
	vessel: {
		name: "",
		siteNumber: "",
		phone: "",
		email: "",
		imoNumber: "",
	},
	assignmentStart: {
		date: "",
		time: "",
	},
	preAssignmentInventory: {
		fuelOil: "",
		soap: "",
		disinfectant: "",
		h2o2: "",
		cip: "",
	},
	generalInfo: {
		operationType: "",
		treatmentType: "",
		specicies: "",
		seaTemp: "",
		breeder: "",
		globalGapCertNumber: "",
		crowdingTime: "",
		approvedTransportRouteAvailable: false,
		passingThroughAreaWithDisease: false,
		completedRiskAssessment11: false,
		informedOfHealthConditionsByBreeder: false,
		diseaseRestrictionsReceived: false,
		shipCleaned: false,
		comments: "",
	},
	shipper: {
		name: "",
		location: "",
		locationNumber: "",
		arrival: {
			date: "",
			time: "",
		},
	},
	recipient: {
		name: "",
		location: "",
		locationNumber: "",
		arrival: {
			date: "",
			time: "",
		},
	},
	loading: {
		wells: {
			well1: {
				penNumber: "",
				start: {
					date: "",
					time: "",
				},
				complete: {
					date: "",
					time: "",
				},
				biomass: "",
				numberFish: "",
				avgWeight: "",
			},
			well2: {
				penNumber: "",
				start: {
					date: "",
					time: "",
				},
				complete: {
					date: "",
					time: "",
				},
				biomass: "",
				numberFish: "",
				avgWeight: "",
			},
			well3: {
				penNumber: "",
				start: {
					date: "",
					time: "",
				},
				complete: {
					date: "",
					time: "",
				},
				biomass: "",
				numberFish: "",
				avgWeight: "",
			},
			well4: {
				penNumber: "",
				start: {
					date: "",
					time: "",
				},
				complete: {
					date: "",
					time: "",
				},
				biomass: "",
				numberFish: "",
				avgWeight: "",
			},
		},
	},
	unloading: {
		uvHours: "",
		filterHours: "",
		estimatedFreshWaterProduced: "",
		comments: "",
		wells: {
			well1: {
				deliveryType: "",
				start: {
					date: "",
					time: "",
				},
				complete: {
					date: "",
					time: "",
				},
			},
			well2: {
				deliveryType: "",
				start: {
					date: "",
					time: "",
				},
				complete: {
					date: "",
					time: "",
				},
			},
			well3: {
				deliveryType: "",
				start: {
					date: "",
					time: "",
				},
				complete: {
					date: "",
					time: "",
				},
			},
			well4: {
				deliveryType: "",
				start: {
					date: "",
					time: "",
				},
				complete: {
					date: "",
					time: "",
				},
			},
		},
	},
	assignmentEnd: {
		date: {
			date: "",
			time: "",
		},
		distance: "",
		totalTime: "",
	},
	assignmentConsumption: {
		fuelOil: {
			startAssignment: "",
			endAssignment: "",
			consumed: "",
		},
		soap: {
			startAssignment: "",
			endAssignment: "",
			consumed: "",
		},
		disinfectant: {
			startAssignment: "",
			endAssignment: "",
			consumed: "",
		},
		h2o2: {
			startAssignment: "",
			endAssignment: "",
			consumed: "",
		},
		cip: {
			startAssignment: "",
			endAssignment: "",
			consumed: "",
		},
	},
}

export const initData3 = {
	vessel: {
		name: "",
		siteNumber: "",
		phone: "",
		email: "",
		imoNumber: "",
	},
	generalInfo: {
		operationType: "",
		specicies: "",
		seaTemp: "",
		breeder: "",
		globalGapCertNumber: "",
		locationLoading: {
			location: "",
			locationNumber: "",
		},
		locationUnloading: {
			location: "",
			locationNumber: "",
		},
		tripNumber: "",
		week: "",
		year: "",
		o2BeforeLoading: "",
		o2AfterLoading: "",
		isFishTiedUp: { yes: false, approvedTransportRouteAvailable: false },
		isFishTransportable: false,
		hasFishBeingHandled: {
			yes: false,
			amountOfTimes: "",
			dateLastHandling: {
				date: "",
				time: "",
			},
			typeHandling: "",
		},
		needTime: "",
		restTime: "",
		passingThroughAreaWithDisease: false,
		completedRiskAssessment11: false,
	},

	loading: {
		loading1: {
			loadNumber: "",
			penNumber: "",
			biomass: "",
			amountFish: "",
			avgWeight: "",
			amountDeadFish: "",
			starvationDays: "",
		},
		loading2: {
			loadNumber: "",
			penNumber: "",
			biomass: "",
			amountFish: "",
			avgWeight: "",
			amountDeadFish: "",
			starvationDays: "",
		},
		loading3: {
			loadNumber: "",
			penNumber: "",
			biomass: "",
			amountFish: "",
			avgWeight: "",
			amountDeadFish: "",
			starvationDays: "",
		},
		loading4: {
			loadNumber: "",
			penNumber: "",
			biomass: "",
			amountFish: "",
			avgWeight: "",
			amountDeadFish: "",
			starvationDays: "",
		},
	},
	unloading: {
		wells: {
			Bb: { volume: "", amountFish: "", biomass: "", wellDensity: "", deliveryType: "" },
			Ct: { volume: "", amountFish: "", biomass: "", wellDensity: "", deliveryType: "" },
			Stb: { volume: "", amountFish: "", biomass: "", wellDensity: "", deliveryType: "" },
		},
		uv: { isRun: false, amountHours: "" },
		liceFilter: { isRun: false, amountHours: "" },
	},
	schedule: {
		assignmentStart: {
			date: "",
			time: "",
		},
		loadingStart: {
			date: "",
			time: "",
		},
		loadingEnd: {
			date: "",
			time: "",
		},
		unloadingStart: {
			date: {
				date: "",
				time: "",
			},
			waterTemp: "",
			o2: "",
		},
		unloadingEnd: {
			date: {
				date: "",
				time: "",
			},
			waterTemp: "",
			o2: "",
		},
		assignmentEnd: {
			date: "",
			time: "",
		},
	},
}
