import { forwardRef } from "react";
import { VariantProps, cva } from "class-variance-authority";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { cn } from "../../lib/utils";

const pageCardBodyVariants = cva("flex h-full w-full flex-row gap-4", {
    variants: {
        company: {
            dipai: "",
        },
    },
    defaultVariants: {
        company: "dipai",
    },
});

export interface IPageCardBodyProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
        VariantProps<typeof pageCardBodyVariants> {}

const PageCardBody = forwardRef<HTMLDivElement, IPageCardBodyProps>(
    (props, ref) => {
        const { className, children, company, ...rest } = props;
        return (
            <div
                ref={ref}
                className={cn(pageCardBodyVariants({ company, className }))}
                {...rest}
            >
                {props.children}
            </div>
        );
    }
);

export { PageCardBody, pageCardBodyVariants };
