import { requestApi2 } from "@utils/http"
import { logError } from "@utils/logError"
import { endpoints, socketio } from "api"
import { socketIoSocket } from "./socketIoSocket"

type Action = socketio.SocketIoMessageData<"userTrackingSubmitAction">

class UserTrackingSocket {
	readonly #buffer = new Array<() => void>()

	#socketIsAuthenticated = false
	#useBuffer = true
	#userIsSignedIn = false

	constructor() {
		socketIoSocket.onConnected.addListener(() => this.#checkAuth().catch(logError))
		socketIoSocket.onDisconnected.addListener(() => {
			this.#socketIsAuthenticated = false
			this.#useBuffer = true
		})
		socketIoSocket.addMessageListener("authResult", (message) => {
			if (message.success !== undefined) {
				this.#socketIsAuthenticated = true
			}
		})
	}

	setUserIsSignedIn() {
		this.#userIsSignedIn = true
		this.#checkAuth().catch(logError)
	}

	submitAction(action: Action) {
		const fn = () => socketIoSocket.sendMessage("userTrackingSubmitAction", action)
		if (this.#useBuffer) {
			this.#buffer.push(fn)
		} else {
			fn()
		}
	}

	async #checkAuth() {
		if (this.#socketIsAuthenticated) {
			// socket is already authenticated
			return
		}
		if (!socketIoSocket.isConnected || !this.#userIsSignedIn) {
			// no socket connection, or not signed in
			return
		}
		// try to authenticate socket
		const request = await requestApi2(endpoints.getSignInKey2)
		if (request == null) {
			return
		}
		socketIoSocket.sendMessage("auth", { key: request.key })
		this.#useBuffer = false
		while (true) {
			const action = this.#buffer.pop()
			if (action == undefined) {
				break
			}
			action?.()
		}
	}
}

export function submitUserTrackingAction(action: Action) {
	userTrackingSocket.submitAction(action)
}

export async function markUserIsAuthenticated() {
	userTrackingSocket.setUserIsSignedIn()
}

const userTrackingSocket = new UserTrackingSocket()
