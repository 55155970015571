import { Chart as ChartJS, ChartType } from "chart.js"

declare module "chart.js" {
	interface PluginOptionsByType<TType extends ChartType> {
		bgColor?: {
			colorMap: {
				[modeName: string]: string
			}
			ranges: [number, number, string][]
		}
	}
}

export const bgColorPlugin = {
	id: "bgColor",
	beforeDatasetsDraw(
		chart: ChartJS,
		args: { cancelable: true },
		pluginOptions: {
			colorMap: {
				[modeName: string]: string
			}
			ranges: [number, number, number][]
		}
	) {
		const {
			ctx,
			chartArea: { top, height, left, right },
			scales: { x },
		} = chart

		if (pluginOptions.ranges === undefined) {
			return
		}
		const opModeColorsMap: number[] = []
		pluginOptions.ranges.forEach((rangeData) => {
			if (opModeColorsMap.includes(rangeData[2]) === false) {
				opModeColorsMap.push(rangeData[2])
			}
		})
		for (const [index, range] of pluginOptions.ranges.entries()) {
			paintBg(
				range[0],
				range[1],
				pluginOptions.colorMap[range[2]] ?? pluginOptions.colorMap["unknown"]!
			)
		}

		function paintBg(bracketLeft: number, bracketRight: number, color: string) {
			if (x === undefined) {
				return
			}
			ctx.save()
			ctx.fillStyle = color
			ctx.fillRect(
				Math.max(x.getPixelForValue(bracketLeft), left),
				top,
				Math.max(
					Math.min(x.getPixelForValue(bracketRight), right) -
						Math.max(x.getPixelForValue(bracketLeft), left),
					0
				),
				height
			)
			ctx.restore()
		}
	},
}
