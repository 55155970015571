import { cn } from "@ui-lib/src/lib"
import { cva } from "class-variance-authority"
import { FC } from "react"
import { IconType } from "react-icons/lib"

const iconVariants = cva("h-full w-full p-1")

// export interface IButtonProps
// 	extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
// 		VariantProps<typeof buttonVariants> {
// 	blockSubmit?: boolean
// }

export interface IButtonIconProps {
	children: IconType
	className?: string
}

export const ButtonIcon: FC<IButtonIconProps> = (props: IButtonIconProps) => {
	const { children: Children, className } = props
	return (
		<Children
			className={cn(iconVariants({ className }))}
			// className={"h-full w-full p-1"}
		/>
	)
}
