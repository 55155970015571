import { RequestStatus } from "@redux/app/RequestStatus"
import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FreightLetter } from "api"
import { initData3 } from "./defaultData"

type WithUndefinedId<T> = {
	[P in keyof T]: T[P] extends number ? T[P] | undefined : T[P]
}

type FreightLetterFE = WithUndefinedId<FreightLetter>

export const INIT_FORM_STATE: FreightLetterFE = {
	id: undefined,
	timestamp: "",
	author: "",
	data: initData3,
	pdfs: [],
}

type SelectedFreightLetter = {
	freightLetter: FreightLetterFE
	showForm: boolean
	status: RequestStatus
	error: string
}

const initialState: SelectedFreightLetter = {
	freightLetter: INIT_FORM_STATE,
	showForm: false,
	status: RequestStatus.idle,
	error: "",
}

const selectedFreightLetter = createSlice({
	name: "selectedFreightLetter",
	initialState,
	reducers: {
		setSelectedFreightLetter(state, action: PayloadAction<FreightLetterFE>) {
			return { ...state, freightLetter: { ...state.freightLetter, ...action.payload } }
		},
		setShowFreightLetterForm(state, action: PayloadAction<boolean>) {
			return { ...state, showForm: action.payload }
		},
		clearSelectedFreightLetter(state) {
			return { ...state, freightLetter: INIT_FORM_STATE }
		},
	},
})

export const getSelectedFreightLetter = (state: RootState) =>
	state.selectedFreightLetter.freightLetter

export const getFreightLetterFormState = (state: RootState) => state.selectedFreightLetter.showForm

export const { setSelectedFreightLetter, clearSelectedFreightLetter, setShowFreightLetterForm } =
	selectedFreightLetter.actions

export default selectedFreightLetter.reducer
