export const isMobileDevice = (): boolean => {
	const userAgent: string =
		navigator.userAgent !== undefined
			? navigator.userAgent
			: navigator.vendor !== undefined
			? navigator.vendor
			: (window as Window & typeof globalThis & { opera?: string }).opera ?? ""

	return /android|iPad|iPhone|iPod/i.test(userAgent) && !/Windows/i.test(userAgent)
}
