import { IconButton, debounce } from "@ui-lib/index"
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react"
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md"

type HTMLDivElementExtended = HTMLDivElement & { initialTouchX?: number; initialTouchY?: number }

export default function CarouselV2({
	children,
	step = 1,
	showPaginationControls = true,
	paginationControlsOverlap = true,
	itemsSpacing = 16,
	direction = "horizontal",
	paginationControlsSize = "medium",
}: {
	children: ReactNode[]
	step?: number
	showPaginationControls?: boolean
	paginationControlsOverlap?: boolean
	itemsSpacing?: number
	direction?: "horizontal" | "vertical"
	paginationControlsSize?: "sm" | "medium" | "lg"
}): ReactElement {
	const dimensionRef = direction === "horizontal" ? "width" : "height"
	const offsetRef = direction === "horizontal" ? "offsetWidth" : "offsetHeight"

	const carouselRefs = useRef<HTMLDivElementExtended | null>(null)
	const nodeRefs = useRef<(HTMLDivElement | null)[]>([])
	const [maxIndex, setMaxIndex] = useState<number>(0)
	const maxIndexRef = useRef(maxIndex)
	const [nodeSizesAccumulated, setNodeSizesAccumulated] = useState<number[]>([])
	const [currentIndex, setCurrentIndex] = useState(0)
	const currentIndexRef = useRef(currentIndex)

	// Function to assign refs to each item
	const assignRef = (el: HTMLDivElement | null, index: number) => {
		if (el !== null && nodeRefs.current[index] !== el) {
			nodeRefs.current[index] = el
		}
	}

	useEffect(() => {
		const sizes: { width: number; height: number }[] = nodeRefs.current.map((ref) => {
			if (ref !== null) {
				const { width, height } = ref.getBoundingClientRect()
				return { width, height }
			}
			return { width: 0, height: 0 }
		})
		setNodeSizesAccumulated(
			sizes.map((s, index) =>
				sizes
					.slice(0, index + 1)
					.reduce(
						(prev, curr, currIndex) =>
							curr[dimensionRef] +
							(currIndex > 0 && currIndex < sizes.length ? itemsSpacing : 0) +
							prev,
						0
					)
			)
		)
		if (carouselRefs.current?.[offsetRef] === undefined) {
			return
		}
		setMaxIndex(
			children.length -
				nodeSizesAccumulated.findIndex((s) => s > carouselRefs?.current?.[offsetRef]!)
		)
	}, [children])

	const next = () => {
		if (currentIndexRef.current < maxIndexRef.current) {
			setCurrentIndex((prevState) => {
				return Math.min(maxIndexRef.current, prevState + step)
			})
		}
	}

	const prev = () => {
		if (currentIndexRef.current > 0) {
			setCurrentIndex((prevState) => {
				return Math.max(0, prevState - step)
			})
		}
	}

	const nodeSizesAccumulated_last = nodeSizesAccumulated[nodeSizesAccumulated.length - 1]
	useEffect(() => {
		if (
			carouselRefs?.current?.[offsetRef] !== undefined &&
			nodeSizesAccumulated_last !== undefined &&
			carouselRefs?.current?.[offsetRef] >= nodeSizesAccumulated_last
		) {
			setCurrentIndex(0)
		}
	}, [carouselRefs?.current?.[offsetRef]])

	// Function to handle the wheel event for zooming
	const handleWheel = debounce((event: WheelEvent) => {
		event.preventDefault() // Prevent the default scroll behavior
		// Implement your zoom logic here based on the event.deltaY value
		if (event.deltaY > 0) {
			next()
		} else if (event.deltaY < 0) {
			prev()
		}
	}, 25)

	// Function to add the wheel event listener
	function addWheelListener() {
		if (carouselRefs.current === null) {
			return
		}
		carouselRefs.current.addEventListener("wheel", handleWheel, {
			passive: false,
		})
	}

	// Function to remove the wheel event listener
	function removeWheelListener() {
		if (carouselRefs.current === null) {
			return
		}
		carouselRefs.current.removeEventListener("wheel", handleWheel)
	}

	// Add event listeners for mouseenter and mouseleave
	useEffect(() => {
		if (carouselRefs.current === null) {
			return
		}
		carouselRefs.current.addEventListener("mouseenter", addWheelListener)
		carouselRefs.current.addEventListener("mouseleave", removeWheelListener)
	}, [carouselRefs])

	useEffect(() => {
		currentIndexRef.current = currentIndex
		maxIndexRef.current = maxIndex
	}, [currentIndex, maxIndex])

	const handleTouchStart = (event: TouchEvent) => {
		const touch_0 = event.touches[0]
		if (carouselRefs.current === null || touch_0 === undefined) {
			return
		}
		if (direction === "vertical") {
			carouselRefs.current.initialTouchY = touch_0.clientY
		} else {
			carouselRefs.current.initialTouchX = touch_0.clientX
		}
	}

	const handleTouchMove = debounce((event: TouchEvent) => {
		const touch_0 = event.touches[0]
		if (carouselRefs.current === null || touch_0 === undefined) {
			return
		}

		const swipeAmount =
			direction === "vertical"
				? touch_0.clientY * 0.9 - (carouselRefs.current?.initialTouchY ?? 0)
				: touch_0.clientX * 0.9 - (carouselRefs.current?.initialTouchX ?? 0)

		if (swipeAmount < 0) {
			setSwipeOffset((prev) => {
				return Math.min(
					prev + Math.abs(swipeAmount),
					nodeSizesAccumulated_last !== undefined &&
						carouselRefs.current?.[offsetRef] !== undefined
						? nodeSizesAccumulated_last - carouselRefs.current?.[offsetRef]
						: 100000
				)
			})
		} else if (swipeAmount > 0) {
			setSwipeOffset((prev) => {
				return Math.max(prev - swipeAmount, 0)
			})
		}
		setCurrentIndex(0)
	}, 10)
	const [swipeOffset, setSwipeOffset] = useState<number>(0)

	useEffect(() => {
		if (carouselRefs.current !== null && nodeSizesAccumulated.length !== 0) {
			carouselRefs.current.addEventListener("touchstart", handleTouchStart)
			carouselRefs.current.addEventListener("touchmove", handleTouchMove)
		}

		return () => {
			if (carouselRefs.current !== null) {
				carouselRefs.current.removeEventListener("touchstart", handleTouchStart)
				carouselRefs.current.removeEventListener("touchmove", handleTouchMove)
			}
		}
	}, [nodeSizesAccumulated])

	const offsetComponentValue =
		currentIndex === 0
			? swipeOffset
			: (nodeSizesAccumulated[
					nodeSizesAccumulated.findIndex((s) => s > carouselRefs?.current?.[offsetRef]!) +
						currentIndex -
						1
			  ] ?? 0) - carouselRefs.current?.[offsetRef]!

	return (
		<div
			ref={carouselRefs}
			className={`flex h-full w-full ${
				direction === "horizontal" ? "flex-col justify-center" : "flex-row justify-center"
			}`}
		>
			{showPaginationControls === true &&
			currentIndex > 0 &&
			carouselRefs?.current?.[offsetRef] !== undefined &&
			nodeSizesAccumulated_last !== undefined &&
			carouselRefs?.current?.[offsetRef] < nodeSizesAccumulated_last ? (
				<IconButton
					onClick={prev}
					size={paginationControlsSize}
					variant={"text-light"}
					className={`absolute z-[1] ${
						paginationControlsOverlap
							? direction === "horizontal"
								? "-left-4"
								: "-top-4 rotate-90"
							: direction === "horizontal"
							? "-left-8"
							: "-top-8 rotate-90"
					}`}
				>
					<IconButton.Icon>{MdNavigateBefore}</IconButton.Icon>
				</IconButton>
			) : null}
			<div className="w-full h-full overflow-hidden">
				<div
					className={`flex flex-shrink-0 flex-grow ${
						direction === "horizontal" ? "flex-row" : "flex-col"
					} transition-all duration-[400ms] ease-in-out`}
					style={{
						gap: itemsSpacing,
						transform: `translate${direction === "horizontal" ? "X" : "Y"}(-${
							offsetComponentValue === undefined ? 0 : offsetComponentValue
						}px)`,
					}}
				>
					{children.map((node, index) => (
						<div key={index} ref={(el) => assignRef(el, index)}>
							{node}
						</div>
					))}
				</div>
			</div>
			{showPaginationControls === true &&
			currentIndex < maxIndex &&
			carouselRefs?.current?.[offsetRef] !== undefined &&
			nodeSizesAccumulated_last !== undefined &&
			carouselRefs?.current?.[offsetRef] < nodeSizesAccumulated_last ? (
				<IconButton
					onClick={next}
					size={paginationControlsSize}
					variant={"text-light"}
					shape={"squared"}
					className={`absolute z-[1] ${
						paginationControlsOverlap
							? direction === "horizontal"
								? "-right-4"
								: "-bottom-4 rotate-90"
							: direction === "horizontal"
							? "-right-8"
							: "-bottom-8 rotate-90"
					}`}
				>
					<IconButton.Icon>{MdNavigateNext}</IconButton.Icon>
				</IconButton>
			) : // <div className={`h-8 w-8 bg-yellow-800`}></div>
			null}
		</div>
	)
}
