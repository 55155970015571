import { RequestStatus } from "@redux/app/RequestStatus"
import { RootState } from "@redux/app/store"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppStructureWithPages } from "@utilityTypes/types"
import { requestApi2 } from "@utils/http"
import { endpoints } from "api"
import { appendPagesToAppStructure } from "./appendPagesToAppStructure"
import { getPagesPerCompany } from "./getPagesPerCompany"

type State = {
	appStructure: AppStructureWithPages | null
	status: RequestStatus
	error: string
}

const initialState: State = {
	appStructure: null,
	status: RequestStatus.idle,
	error: "",
}

export const fetchAppStructure = createAsyncThunk<AppStructureWithPages | null>(
	"appStructure/fetchAppStructure",
	async (): Promise<AppStructureWithPages | null> => {
		const abortController = new AbortController()
		const appStructure = await requestApi2(
			endpoints.getAppStructure,
			undefined,
			abortController.signal
		)
		if (appStructure !== null) {
			const pagesPerCompany = getPagesPerCompany(appStructure)
			const appStructureWithPages = appendPagesToAppStructure(appStructure, pagesPerCompany)

			return appStructureWithPages
		}

		if (!abortController.signal.aborted) {
			console.log(`error: ${"Server error"}`)
		}
		return null
	}
)

const appStructureSlice = createSlice({
	name: "appStructure",
	initialState,
	reducers: {
		setAppStructure(state, action: PayloadAction<AppStructureWithPages>) {
			return { ...state, appStructure: { ...action.payload } }
		},
		clearAppStructure(state) {
			return { ...state, ...initialState }
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchAppStructure.pending, (state, action) => {
				state.status = RequestStatus.loading
			})
			.addCase(fetchAppStructure.fulfilled, (state, action) => {
				state.status = RequestStatus.succeeded
				state.appStructure = action.payload
			})
			.addCase(fetchAppStructure.rejected, (state, action) => {
				state.status = RequestStatus.failed
				state.error = "" //action.error.message
			})
	},
})

export const getAppStructure = (state: RootState) => state.appStructure.appStructure
export const getAppStructureStatus = (state: RootState) => state.appStructure.status

export const { setAppStructure, clearAppStructure } = appStructureSlice.actions

export default appStructureSlice.reducer
