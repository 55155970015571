import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { FC, useState } from "react"
import { getI18n, useTranslation } from "react-i18next"
import { MdLanguage } from "react-icons/md"
import { Tooltip } from "../../Tooltip"
import { usePageLayoutContext } from "../PageLayout"

const LanguageDropdown = ({
	isExpanded,
	languages,
	selectedLanguage,
	changeLanguage,
	translationFunc,
}: {
	isExpanded: boolean
	languages: { name: string; code: string }[]
	selectedLanguage: string
	changeLanguage: (lng: string) => void
	translationFunc: ReturnType<typeof useTranslation>["t"] | undefined
}) => {
	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger
				className={`${
					isExpanded === false ? `w-[60px]` : `w-[180px] `
				} text-white text-opacity-60 ring-inset transition-all duration-300 hover:text-opacity-100 focus:outline-none focus:ring-[0px] focus:ring-dipai-primary-501 focus:ring-opacity-40`}
			>
				<div
					tabIndex={-1}
					className={`group flex ${
						isExpanded === false ? `w-[60px]` : `w-[180px]`
					} flex-row  `}
				>
					<Tooltip
						className={`${isExpanded === true ? `hidden` : `block`}`}
						sideOffset={24}
						side={"right"}
						customContent={
							translationFunc !== undefined ? translationFunc("LANGUAGE") : "LANGUAGE"
						}
					>
						<div tabIndex={-1} className={`flex h-[52px] items-center pl-[14px] pr-3`}>
							<MdLanguage className={`h-6 w-6`} />
						</div>
					</Tooltip>
					<div
						tabIndex={-1}
						className={`h-[52px] w-[130px] origin-left ${
							isExpanded === false ? `scale-x-0` : `scale-x-100`
						} duration-300`}
					>
						<div className={`flex h-full items-center overflow-hidden`}>
							<p
								className={`w-[110px] text-left text-button font-semibold leading-[16px] text-white duration-300 group-hover:text-opacity-100
										 ${isExpanded === false ? `text-opacity-0` : `text-opacity-60`}`}
							>
								{`${
									languages.find((lang) => lang.code === selectedLanguage)
										?.name ?? "unknown language"
								}`}
							</p>
						</div>
					</div>
				</div>
			</DropdownMenu.Trigger>
			<DropdownMenu.Content
				onCloseAutoFocus={(e) => e.preventDefault()}
				side={"right"}
				sideOffset={-10}
				align={"end"}
				alignOffset={10}
				className="z-[1000] mt-2 w-[200px] rounded-sm border-1 border-dipai-secondary-901 bg-dipai-secondary-800 p-[6px] text-white text-opacity-60 shadow-popup"
			>
				{languages.map((lang) => (
					<DropdownMenu.Item
						key={`languageSelector_${lang.name}`}
						className={`${
							selectedLanguage === lang.code
								? `px-[14px] py-1 text-menu text-dipai-primary-501`
								: `cursor-pointer rounded-sm px-[14px] py-1 text-menu ring-inset hover:bg-white hover:bg-opacity-20 hover:text-white hover:text-opacity-100 hover:outline-none focus:outline-none focus:ring-[0px] focus:ring-dipai-primary-501 focus:ring-opacity-40`
						}`}
						onSelect={(e) => {
							e.preventDefault()
							changeLanguage(lang.code)
						}}
					>
						<p>{lang.name}</p>
					</DropdownMenu.Item>
				))}
			</DropdownMenu.Content>
		</DropdownMenu.Root>
	)
}

export interface ILanguageSelectorProps {
	i18n: ReturnType<typeof getI18n> & {}
	languages: { name: string; code: string }[]
}
export const MainNavLanguageSelector: FC<ILanguageSelectorProps> = (
	props: ILanguageSelectorProps
) => {
	const { i18n, languages } = props
	const { isMainNavExpanded, isMainNavPopupExpanded, translationFunc } = usePageLayoutContext()
	const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)
	const changeLanguage = (lng: string) => {
		setSelectedLanguage(lng)
		i18n.changeLanguage(lng)
	}

	return (
		<>
			<div className={`hidden lg:flex`}>
				<LanguageDropdown
					changeLanguage={changeLanguage}
					languages={languages}
					selectedLanguage={selectedLanguage}
					isExpanded={isMainNavExpanded}
					translationFunc={translationFunc}
				/>
			</div>
			<div className={`flex sm:flex lg:hidden`}>
				<LanguageDropdown
					changeLanguage={changeLanguage}
					languages={languages}
					selectedLanguage={selectedLanguage}
					isExpanded={isMainNavPopupExpanded}
					translationFunc={translationFunc}
				/>
			</div>
		</>
	)
}
