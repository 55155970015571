import { FC, ReactElement } from "react"
import { ReactElementExtended } from "./ReportGenerator"

type DimensionSpan =
	| "20%"
	| "25%"
	| "33.33%"
	| "40%"
	| "50%"
	| "60%"
	| "66.66%"
	| "75%"
	| "80%"
	| "100%"

export interface IContentWrapperProps {
	children: ReactElement<ReactElementExtended>
	idKey: string
	rowSpan: DimensionSpan
	colSpan: DimensionSpan
}

export const findSpanOutOf60 = (spanPercentage: DimensionSpan) => {
	switch (spanPercentage) {
		case "20%":
			return 12
		case "25%":
			return 15
		case "33.33%":
			return 20
		case "40%":
			return 24
		case "50%":
			return 30
		case "60%":
			return 36
		case "66.66%":
			return 40
		case "75%":
			return 45
		case "80%":
			return 48
		default:
			return 60
	}
}
export const ContentWrapper: FC<IContentWrapperProps> = (props) => {
	const rowSpanOutOf60 = findSpanOutOf60(props.rowSpan)
	const colSpanOutOf60 = findSpanOutOf60(props.colSpan)
	return (
		<div
			className={`gap-10 overflow-hidden`}
			key={props.idKey}
			style={{
				gridColumn: `span ${colSpanOutOf60} / span ${colSpanOutOf60}`,
				gridRow: `span ${rowSpanOutOf60} / span ${rowSpanOutOf60}`,
			}}
		>
			{props.children}
		</div>
	)
}
