import { Chart as ChartJS, ChartType, Plugin } from "chart.js"

declare module "chart.js" {
	interface PluginOptionsByType<TType extends ChartType> {
		verticalLine?: VerticalLinePluginProps
	}
}

type DefaultOptions = {
	line?: {
		color: string
		width: number
		dashPattern: number[]
	}
}
export type VerticalLinePluginProps = DefaultOptions & {
	refTimestamp: number | undefined
}

const defaultOptions: Required<DefaultOptions> = {
	line: {
		color: "#03F",
		width: 2,
		dashPattern: [2, 2],
	},
}

export const verticalLine: Plugin<"line"> = {
	id: "verticalLine",
	beforeDraw: (chart: ChartJS<"line">, args, options: VerticalLinePluginProps) => {
		const {
			ctx,
			chartArea: { top, bottom, right, left },
			scales: { x },
		} = chart
		const refTimestamp = options.refTimestamp

		if (refTimestamp === undefined || x === undefined) {
			return
		}
		const xPos = x.getPixelForValue(refTimestamp)

		if (xPos < left || xPos > right) {
			return
		}
		ctx.save()
		ctx.beginPath()
		ctx.moveTo(xPos, top)
		ctx.lineTo(xPos, bottom)
		ctx.lineWidth = options.line?.width ?? defaultOptions.line.width
		ctx.strokeStyle = options.line?.color ?? defaultOptions.line.color
		ctx.setLineDash(options.line?.dashPattern ?? defaultOptions.line.dashPattern)
		ctx.stroke()
		ctx.restore()
	},
}
