import { FC, ReactNode, useEffect, useState } from "react"
import { MdClose, MdKeyboardDoubleArrowRight } from "react-icons/md"
import { useSearchParamsState } from "../../../lib/hooks/useSearchParamsState"
import { IconButton } from "../../IconButton"
import { Tooltip } from "../../Tooltip"
import { usePageLayoutContext } from "../PageLayout"
import { SecondaryNavItem } from "./SecondaryNavItem"

const transitionTypes = "[transition:transform_0.3s,opacity_0.3s]"
const transitionDuration = `duration-[300ms]`

export interface ISecondaryNavProps {
	label: string
	items: { plantName: string; icons?: ReactNode[] }[]
}

export const SecondaryNav: FC<ISecondaryNavProps> = (props: ISecondaryNavProps) => {
	const { label, items } = props
	const [paramSelectedVessel, setParamSelectedVessel] = useSearchParamsState("vessel", "")
	const {
		selectedModuleMetadata,
		translationFunc,
		isSecondaryNavExpanded,
		setIsSecondaryNavExpanded,
	} = usePageLayoutContext()

	const [searchTerm, setSearchTerm] = useState("")
	const [filteredItems, setFilteredItems] =
		useState<{ plantName: string; icons?: ReactNode[] }[]>(items)

	useEffect(() => {
		const filtered = items.filter((item) =>
			item.plantName.toLowerCase().includes(searchTerm.toLowerCase())
		)
		setFilteredItems(filtered)
	}, [items])

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value
		setSearchTerm(value)
		const filtered = items.filter((item) =>
			item.plantName.toLowerCase().includes(value.toLowerCase())
		)
		setFilteredItems(filtered)
	}
	const handleClear = () => {
		setSearchTerm("")
		setFilteredItems(items)
	}

	return (
		<>
			{selectedModuleMetadata === undefined ||
			selectedModuleMetadata.config?.hideVesselSelection === true ? null : (
				<div
					id="secondaryNav"
					className={`absolute top-[52px] z-[99] flex h-[calc(100vh-52px)] w-auto flex-col justify-between border-r-0 border-dipai-secondary-800 bg-dipai-secondary-901 shadow-side-menu-l sm:relative sm:top-0 sm:h-[100vh]`}
				>
					<div
						className={`transition-[width] ${transitionDuration} ${
							isSecondaryNavExpanded === false ? "w-[52px] sm:w-9" : "w-[212px]"
						}`}
					>
						<div
							className={`flex h-[52px] w-full items-center border-b-gray-900 border-opacity-20 text-title5 font-bold text-white text-opacity-90 ${
								isSecondaryNavExpanded === true
									? "border-b-1 shadow-[0px_1.5px_1px_hsla(0,0%,100%,0.15)]"
									: ""
							}`}
						>
							<p
								className={`ml-3 origin-left ${transitionTypes} ${
									isSecondaryNavExpanded === false
										? "scale-x-0 opacity-0"
										: "scale-x-100 opacity-100"
								}`}
							>
								{translationFunc !== undefined
									? translationFunc(label.toUpperCase())
									: label}
							</p>
						</div>
						<div
							className={`relative m-3 w-[calc(100%-24px)] origin-left ${transitionTypes} ${
								isSecondaryNavExpanded === false
									? "scale-x-0 opacity-0"
									: "scale-x-100 opacity-100"
							}`}
						>
							<input
								className={`h-8 w-full rounded-sm border-1 border-gray-ccc border-opacity-90 bg-white bg-opacity-10 py-[2px] pl-2 pr-8 text-small text-white placeholder:text-gray-ccc hover:cursor-text hover:border-dipai-primary-501 focus:border-2 focus:border-dipai-primary-501 focus:py-[1px] focus:pl-[7px] focus:outline-none focus:ring-0`}
								type="text"
								placeholder={
									translationFunc !== undefined
										? translationFunc("SEARCH VESSEL")
										: "Search vessel..."
								}
								value={searchTerm}
								onChange={handleSearch}
							/>
							{searchTerm !== "" ? (
								<IconButton
									className={`absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer border-none text-white text-opacity-60 hover:text-white hover:text-opacity-100`}
									variant={"text-light"}
									size={"sm"}
									onClick={handleClear}
								>
									<IconButton.Icon>{MdClose}</IconButton.Icon>
								</IconButton>
							) : null}
						</div>
						<div
							className={`ml-3 h-[calc(100vh-52px-52px-56px-52px)] w-[calc(100%-12px)] origin-left overflow-auto overflow-x-hidden pb-3 pr-3 sm:h-[calc(100vh-52px-52px-56px)] ${transitionTypes} ${
								isSecondaryNavExpanded === false
									? "scale-x-0 opacity-0"
									: "scale-x-100 opacity-100"
							}`}
						>
							{filteredItems
								.sort((a, b) =>
									a.plantName > b.plantName
										? 1
										: a.plantName < b.plantName
										? -1
										: 0
								)
								.map((item, index) => (
									<div
										tabIndex={-1}
										key={`${item}_${index}`}
										onClick={() => setParamSelectedVessel(item.plantName)}
									>
										<SecondaryNavItem
											isSelected={item.plantName === paramSelectedVessel}
											label={item.plantName}
											icons={item.icons}
										/>
									</div>
								))}
						</div>
						<div
							className={`w-[100vh] gap-4 overflow-clip pr-[84px] transition-[height] duration-0 sm:pr-[28px] ${
								isSecondaryNavExpanded === true
									? "h-0 delay-[0ms]"
									: "h-[52px] delay-[200ms] sm:h-[36px]"
							} absolute -right-[0px] bottom-[-10px] left-[26px] flex origin-left -rotate-90 flex-row items-center justify-end sm:left-[18px]`}
						>
							{paramSelectedVessel === "" ? (
								<p
									className={`whitespace-nowrap text-title5 font-bold text-white text-opacity-90`}
								>
									{translationFunc !== undefined
										? translationFunc(label.toUpperCase())
										: label}
								</p>
							) : (
								<>
									<p
										className={`whitespace-nowrap text-title5 font-bold text-white text-opacity-90`}
									>
										{paramSelectedVessel}
									</p>
									{
										items.find((item) => item.plantName === paramSelectedVessel)
											?.icons
									}
								</>
							)}
						</div>
						<div
							className={`flex h-[52px] w-full items-center justify-end  border-opacity-20 pr-1 ${
								isSecondaryNavExpanded === true
									? "border-t-1 border-t-gray-900 shadow-[inset_0px_1.5px_1px_hsla(0,0%,100%,0.15)]"
									: ""
							}`}
						>
							<Tooltip
								side={"right"}
								sideOffset={20}
								customContent={
									translationFunc !== undefined
										? translationFunc(
												isSecondaryNavExpanded === true
													? "SHOW LESS"
													: "SHOW MORE"
										  )
										: isSecondaryNavExpanded === true
										? "SHOW LESS"
										: "SHOW MORE"
								}
							>
								<button
									onMouseDown={(e) => {
										const elem = document.activeElement
										if (elem instanceof HTMLElement) {
											elem.blur()
										}
										e.preventDefault()
									}}
									onClick={() => {
										// localStorage.setItem(
										// 	"isSecondaryNavExpanded",
										// 	`${!isSecondaryNavExpanded}`
										// )
										setIsSecondaryNavExpanded((prev) => !prev)

										// Ensures that map components are properly resized and updated
										var event = new Event("resize")
										setTimeout(() => {
											window.dispatchEvent(event)
										}, 500)
									}}
									className={`focus-style h-6 w-6 cursor-pointer text-white text-opacity-60 hover:text-opacity-100`}
								>
									<MdKeyboardDoubleArrowRight
										tabIndex={-1}
										className={`h-5 w-5 scale-y-125 transition-transform ${transitionDuration} ease-in-out ${
											isSecondaryNavExpanded === false
												? `rotate-0`
												: `rotate-180`
										}`}
									/>
								</button>
							</Tooltip>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
