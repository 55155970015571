import { Component } from "react"

class ErrorBoundary extends Component {
	state = { hasError: false, error: null }
	static getDerivedStateFromError(error) {
		return { hasError: true }
	}

	componentDidCatch(error, info) {
		// add some logic here to send log to the BE
		// console.log(info.componentStack)
		// console.log(error)
		this.setState({ error: error })
	}

	render() {
		if (this.state.hasError === true) {
			return <this.props.fallback error={this.state.error} />
		}
		return this.props.children
	}
}

export default ErrorBoundary
