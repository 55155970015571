import { FC } from "react"
import { IconType } from "react-icons/lib"

export interface IButtonIconProps {
	className?: string
	children: IconType
}

export const ButtonIcon: FC<IButtonIconProps> = (props: IButtonIconProps) => {
	const { children: Children, className } = props
	return <Children className={className} />
}
