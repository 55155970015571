import { useState } from "react"
import { useTranslation } from "react-i18next"
import { MdError } from "react-icons/md"

export default function ErrorMessagePage({ error }: { error: Error | null }) {
	const { t } = useTranslation()
	const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)
	return (
		<div className={`h-screen bg-dipai-secondary-901 text-white`}>
			{/* <div className="flex h-[52px] flex-row items-center justify-end">
				<p className="pr-4 text-small">
					{t !== undefined
						? t("PROBLEMS? PLEASE CONTACT ")
						: "Problems? Please contact "}
					<span className="font-semibold underline">Dipai Support</span>
				</p>
			</div> */}

			<div className="flex h-[60vh] w-full flex-row items-end justify-center">
				<div className={`flex flex-col items-center`}>
					<div className={`mb-5 rounded-full bg-dipai-primary-501`}>
						<MdError className={`h-24 w-24 fill-dipai-secondary-901`} />
					</div>
					<p className="mb-2 h-[49px] text-center text-title3 font-bold">
						{t !== undefined
							? t("AN ERROR HAPPENED IN THE APPLICATION")
							: "An error happened in the application"}
					</p>
					<p className="mb-8 text-center text-body">
						{t !== undefined
							? t("IF THE ISSUE PERSISTS, PLEASE CONTACT DIPAI SUPPORT")
							: "If the issue persists, please contact Dipai support."}
					</p>
				</div>
			</div>
			{/* <div className="flex h-[40vh] w-full flex-col items-center justify-start gap-2 px-4">
				<Button
					className={`w-[160px]`}
					variant={"text-light"}
					onClick={() => setShowErrorMessage((prev) => !prev)}
				>
					<Button.Label>
						{t !== undefined
							? t(showErrorMessage !== true ? "SHOW ERROR" : "HIDE ERROR")
							: showErrorMessage !== true
							? "Show error"
							: "Hide error"}
					</Button.Label>
				</Button>
				<div
					className={`max-h-[calc(40vh-60px)] flex-col justify-start overflow-y-auto rounded-sm border-1 p-2 ${
						showErrorMessage === true ? "flex" : "hidden"
					}`}
				>
					{error?.stack !== undefined ? (
						error?.stack
							?.split("\n")
							.map((errorLine, idx) => (
								<p
									key={errorLine}
									className={`${idx === 0 ? "font-semibold" : "font-normal"}`}
								>{`${errorLine}`}</p>
							))
					) : (
						<p>{`${error?.name}: ${error?.message}`}</p>
					)}
				</div>
			</div> */}
		</div>
	)
}
