import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "@redux/app/store"
import { PointCollection } from "@components/map/utils"

const initialState: PointCollection = {
	type: "FeatureCollection",
	features: [],
}

const filteredListOfFishFarmsSlice = createSlice({
	name: "filteredListOfFishFarms",
	initialState,
	reducers: {
		setFilteredListOfFishFarms(state, action: PayloadAction<PointCollection>) {
			return { ...state, ...action.payload }
		},
	},
})

export const getFilteredListOfFishFarms = (state: RootState) => state.filteredListOfFishFarms

export const { setFilteredListOfFishFarms } = filteredListOfFishFarmsSlice.actions

export default filteredListOfFishFarmsSlice.reducer
