import { VesselInfoWithOpMode } from "@components/map/shipIcon"
import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type TimelineEventMarker = VesselInfoWithOpMode & {
	coords?: { lat: number; lon: number }
}

const initialState: TimelineEventMarker = {
	heading: undefined,
	latestOperationMode: undefined,
	coords: undefined,
}

const timelineEventMarkerSlice = createSlice({
	name: "timelineEventMarker",
	initialState,
	reducers: {
		setTimelineEventMarker(state, action: PayloadAction<TimelineEventMarker>) {
			return { ...state, ...action.payload }
		},
		clearTimelineEventMarker(state) {
			return { ...state, ...initialState }
		},
	},
})

export const getTimelineEventMarker = (state: RootState) => state.timelineEventMarker

export const { setTimelineEventMarker, clearTimelineEventMarker } = timelineEventMarkerSlice.actions

export default timelineEventMarkerSlice.reducer
