import { AppPageProps, AppStructureWithPages } from "@utilityTypes/types"
import { ComponentType, ReactElement, lazy } from "react"

type PageImports = Record<string, () => Promise<{ default: ComponentType<AppPageProps> }>>

const pages = import.meta.glob("../../pages/**/*") as PageImports
const pagesSoelvtrans = import.meta.glob("../../soelvtrans/**/*") as PageImports
const pagesSOP = import.meta.glob("../../sop/**/*") as PageImports

type LoadPageProps = {
	pageRef: string
	props: AppStructureWithPages
	basePath?: string
	path: string
	pageId: string
}

export function loadPage(props: LoadPageProps): ReactElement {
	const Page =
		props.pageRef.split("/")[0] === "soelvtrans"
			? lazy(() => {
					const loadFunction = pagesSoelvtrans[`../../${props.pageRef}.tsx`]
					if (loadFunction !== undefined) {
						return loadFunction()
					} else {
						return new Promise(() => {
							return { default: <></> }
						})
					}
			  })
			: props.pageRef.split("/")[0] === "sop"
			? lazy(() => {
					const loadFunction = pagesSOP[`../../${props.pageRef}.tsx`]
					if (loadFunction !== undefined) {
						return loadFunction()
					} else {
						return new Promise(() => {
							return { default: <></> }
						})
					}
			  })
			: lazy(() => {
					const loadFunction = pages[`../../pages/${props.pageRef}.tsx`]
					if (loadFunction !== undefined) {
						return loadFunction()
					} else {
						return new Promise(() => {
							return { default: <></> }
						})
					}
			  })

	return (
		<Page
			props={props.props}
			basePath={props.basePath ?? ""}
			path={props.path}
			pageId={props.pageId}
		/>
	)
}
