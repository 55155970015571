import { configureStore } from "@reduxjs/toolkit"
// REDUCERS
import appStateReducer from "@redux/features/appState/appStateSlice"
import appStructureReducer from "@redux/features/appStructure/appStructureSlice"
import currentCompanyReducer from "@redux/features/currentCompany/currentCompanySlice"
import diseaseZonesReducer from "@redux/features/diseaseZones/diseaseZonesSlice"
import facilitiesReducer from "@redux/features/facilities/facilitiesSlice"
import filteredListOfFishFarmsReducer from "@redux/features/filteredListOfFishFarms/filteredListOfFishFarmsSlice"
import fishFarmOwnersReducer from "@redux/features/fishFarmOwners/fishFarmOwnersSlice"
import listOfVesselsReducer from "@redux/features/listOfVessels/listOfVesselsSlice"
import mapFiltersReducer from "@redux/features/mapFilters/mapFiltersSlice"
import mapFiltersFacilitiesReducer from "@redux/features/mapFiltersFacilities/mapFiltersFacilitiesSlice"
import mapFiltersOilReducer from "@redux/features/mapFiltersOil/mapFiltersOilSlice"
import mapFiltersPanelStateReducer from "@redux/features/mapFiltersPanelState/mapFiltersPanelStateSlice"
import mapSidePanelControlReducer from "@redux/features/mapSidePanelControl/mapSidePanelControlSlice"
import offlineVesselsReducer from "@redux/features/offlineVessels/offlineVesselsSlice"
import routeTracerReducer from "@redux/features/routeTracerData/routeTracerDataSlice"
import selectedBunkerReportReducer from "@redux/features/selectedBunkerReport/selectedBunkerReportSlice"
import selectedFreightLetterReducer from "@redux/features/selectedFreightLetter/selectedFreightLetterSlice"
import selectedMrvSubEventReducer from "@redux/features/selectedMrvSubEvent/selectedMrvSubEventSlice"
import selectedVesselReducer from "@redux/features/selectedVessel/selectedVesselSlice"
import stateAliasesReducer from "@redux/features/stateAliases/stateAliasesSlice"
import timelineEventMarkerReducer from "@redux/features/timelineEventMarker/timelineEventMarkerSlice"
import userDataReducer from "@redux/features/userData/userDataSlice"

export const store = configureStore({
	reducer: {
		appState: appStateReducer,
		facilities: facilitiesReducer,
		mapFiltersFacilities: mapFiltersFacilitiesReducer,
		offlineVessels: offlineVesselsReducer,
		userData: userDataReducer,
		appStructure: appStructureReducer,
		stateAliases: stateAliasesReducer,
		mapFiltersOil: mapFiltersOilReducer,
		mapFiltersPanelState: mapFiltersPanelStateReducer,
		routeTracerData: routeTracerReducer,
		selectedBunkerReport: selectedBunkerReportReducer,
		selectedMrvSubEvent: selectedMrvSubEventReducer,
		selectedVessel: selectedVesselReducer,
		currentCompany: currentCompanyReducer,
		mapFilters: mapFiltersReducer,
		mapSidePanelControl: mapSidePanelControlReducer,
		listOfVessels: listOfVesselsReducer,
		timelineEventMarker: timelineEventMarkerReducer,
		selectedFreightLetter: selectedFreightLetterReducer,
		filteredListOfFishFarms: filteredListOfFishFarmsReducer,
		fishFarmOwners: fishFarmOwnersReducer,
		diseaseZones: diseaseZonesReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: { warnAfter: 100 },
		}),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const getAppState = (state: RootState) => state.appState
